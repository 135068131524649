import React, { useState, useEffect } from "react";
import InfoIcon from "@material-ui/icons/Info";
import PlayCircleFilledWhiteIcon from "@material-ui/icons/PlayCircleFilledWhite";
import { useHistory } from "react-router-dom";
import { useAuth } from "../../providers/auth";
import axios from "../../axiosfetch";
import Swal from 'sweetalert2';
import "./MovieCard.css";

const MovieCard = ({ fake, id, title, poster, watching, course_id, disciplina }) => {
  const history = useHistory();
  const { user } = useAuth();
  let timeLeft = Math.floor(Math.random() * 99) + 1;
  let barWidth = 100 - timeLeft;

  const redirectToPlay = async (id) => {
    if (fake) {
      console.log('Material mockado para demo');
    } else {
      try {
        const response = await axios.get(
          `/enroll_course?course_id=${course_id}&auth_token=${user.token}`
        );
        history.push(`/play/${id}`);
      } catch (error) {
        Swal.fire({
          title: `${disciplina ? disciplina : 'Conteúdo'}`,
          text: 'Vímos que você ainda não comprou este curso, então não perca tempo, garanta logo a sua vaga no curso.',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Fechar',
          customClass: {
            popup: 'dark-mode', // Adiciona a classe 'dark-mode' para o modo escuro
          },
        });
        return;
      }
    }
    
  };

  return (
    <div className={watching ? "movieCard watching" : "movieCard "}>
      <a onClick={() => redirectToPlay(id)} style={{ cursor: "pointer"}}>
        <div className="movieCard__container">
          {poster == null ? (
            <span className="movieCard__title">{title}</span>
          ) : null}
          {watching ? (
            <>
              <div className="movieCard__progressBar">
                <div
                  style={{ width: barWidth + "%" }}
                  className="movieCard__colorBar"
                ></div>
              </div>
              <div className="movieCard__watchingData">
                <h3>{title}</h3>
                <div className="movieCard__lowerPart">
                  <div className="movieCard__buttons">
                    <InfoIcon />
                    <PlayCircleFilledWhiteIcon />
                  </div>
                  <span className="movieCard__timeLeft">
                    {timeLeft} min left
                  </span>
                </div>
              </div>
            </>
          ) : null}
          <img
            className="movieCard__poster"
            src={
              poster !== null
                ? poster
                : "https://firebasestorage.googleapis.com/v0/b/chromatic-craft-400013.appspot.com/o/images%2Fno-image%2Fcard.jpg?alt=media&token=7c555252-bb79-49c3-863a-3a770d67e6f6&_gl=1*1p34wqx*_ga*NjMzNTUwOTI5LjE2OTU4NzAwNDc.*_ga_CW55HF8NVT*MTY5NjIxMzMwNC41LjEuMTY5NjIxODgxNS40Ny4wLjA."
                // : "../noImage.png"
            }
            // alt={title}
          />
        </div>
      </a>
      {/* </Link> */}
    </div>
  );
};

export default MovieCard;
