import React, { useState, useEffect } from "react";
import MovieCard from "../MovieCard";
import Slider from "react-slick";
import { withRouter } from "react-router-dom";
import { sliderConfig } from "../../utils";
import Skeleton from "@material-ui/lab/Skeleton";
import { useAuth } from "../../providers/auth";
import useSingleCards from "../../hooks/useSingleCards";
import useEnrollCurse from "../../hooks/useEnrollCurse";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./SingleRowCards.css";

const SingleRowCards = ({ datafake, title, fetchUrl, watching }) => {
  const { user } = useAuth();
  const { movies } = useSingleCards(fetchUrl);
  const dadosAula = datafake || movies;

  return (
    <>
      {movies.length > 0 ? (
        <article className="singleRow">
          <h2 className="singleRow__title">{title}</h2>
          <Slider className="singleRow__slider" {...sliderConfig}>
            {dadosAula.map((movie) => (
              <MovieCard
                id={movie.id}
                key={movie.id}
                poster={movie.card_img ? movie.card_img : 'https://firebasestorage.googleapis.com/v0/b/chromatic-craft-400013.appspot.com/o/images%2Fno-image%2Fcard.jpg?alt=media&token=7c555252-bb79-49c3-863a-3a770d67e6f6&_gl=1*1ogn7ht*_ga*NjMzNTUwOTI5LjE2OTU4NzAwNDc.*_ga_CW55HF8NVT*MTY5NjIxMzMwNC41LjEuMTY5NjIxNTcwOC41NC4wLjA.'}
                title={movie.title}
                watching={watching}
                course_id={movie.course_id}
                disciplina={movie.course.title}
              />
            ))}
          </Slider>
        </article>
      ) : (
        <Skeleton animation="wave" variant="rect" width="100%" height={200} />
      )}
    </>
  );
};

export default withRouter(SingleRowCards);
