import React, { useState, useEffect } from "react";
import InfoIcon from "@material-ui/icons/Info";
import PlayCircleFilledWhiteIcon from "@material-ui/icons/PlayCircleFilledWhite";
import { Link } from "react-router-dom";
import noImage from '../../assets/images/brands/capa-teoria.jpg';
import "./CardAposila.css";

const CardAposila = ({ id, title, poster, watching, urlPDF, duration, disciplina }) => {
  let timeLeft = Math.floor(Math.random() * 99) + 1;
  let barWidth = 100 - timeLeft;

  return (
    <div className={watching ? "movieCard watching" : "movieCard "}>
      <Link
        to={{
          pathname: `/teoria/${id}`,
          state: { urlPDF, title, watching, disciplina } // Passando os parâmetros adicionais usando state
        }}
      >
        <div className="movieCard__container">
          {poster == null ? (
            <span className="movieCard__title">{title}</span>
          ) : null}
          {watching ? (
            <>
              <div className="movieCard__watchingData">
                <h5>{title}</h5>
              </div>
            </>
          ) : null}
          <img
            className="movieCard__poster"
            src={
              poster !== null
                ? noImage
                : "../noImage.png"
            }
            alt={title}
          />
        </div>
      </Link>
    </div>
  );
};

export default CardAposila;
