import React, { useEffect } from "react";
import { useAuth } from "../../providers/auth";
import SingleRowCards from "../SingleRowCards";
import SingleRow from "../SingleRow";
import requests from "../../requests";
import "./MoviesRows.css";
import aulasConcursos from '../../aulas';

const MoviesRows = () => {
  const { user } = useAuth();

  return (
    <section className="moviesRows">
      {/* <SingleRow title={"Continue Assistindo"} fetchUrl={requests.fetchRandom} watching={true}/> */}
      {/* <SingleRowCards title={"FASPM"} fetchUrl={requests.fetchFaspm+user.token}  watching={false}/> */}
      <SingleRowCards title={"Enem Pará"} fetchUrl={requests.fetchEnemPa+user.token}  watching={false}/>
      {/* <SingleRow title={"Enem Pará"} datafake={aulasConcursos} fetchUrl={requests.fetchDisney}  watching={false}/> */}
      {/* <SingleRow title={"Star Wars"} fetchUrl={requests.fetchStarWars}  watching={false}/>
      <SingleRow title={"Pixar Movies"} fetchUrl={requests.fetchPixar}  watching={false}/>
      <SingleRow title={"Action and Adventures"} fetchUrl={requests.fetchMarvel}  watching={false}/>
      <SingleRow title={"The Simpsons"} fetchUrl={requests.fetchSimpsons}  watching={false}/>
      <SingleRow title={"Musicales"} fetchUrl={requests.fetchMusicPixar}  watching={false}/>
      <SingleRow title={"Documentaries"} fetchUrl={requests.fetchDocumentaries}
       watching={false}/> */}
    </section>
  );
};

export default MoviesRows;
