import React, { useState, useEffect } from "react";
import { useAuth } from "../../providers/auth";
import Button from "@material-ui/core/Button";
import Slider from "react-slick";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import ThumbUpAltIcon from "@material-ui/icons/ThumbUpAlt";
import ThumbDownAltIcon from "@material-ui/icons/ThumbDownAlt";
import GroupIcon from "@material-ui/icons/Group";
import MovieCardDisciplina from "../MovieCardDisciplina";
import CardAposila from "../CardAposila";
import CardAtividade from "../CardAtividade";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import { withRouter, Redirect, useHistory } from "react-router-dom";
import { apiKey, sliderConfig } from "../../utils";
import useDetailPage from "../../hooks/useDetailPage";
import axios from "../../axiosfetch";
import Swal from "sweetalert2";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./DetailPage.css";
import "../SingleRow/SingleRow.css";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return <div {...other}>{value === index && <Box p={3}>{children}</Box>}</div>;
}

const DetailPage = (props) => {
  const history = useHistory();
  const movieId = props.match.params.movieId;
  const { user } = useAuth();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [value, setValue] = useState(0);
  // const { enrollCourse } = useEnrollCurse(
  //   movieId,
  //   user.token
  // );

  const {
    singleMovie,
    recommended,
    apostilas,
    videos,
    disciplina,
    cast,
    courseDetails,
    quiz,
  } = useDetailPage(movieId, apiKey);

  const isAuthenticated = !!user.token;

  // console.log("Quiz", quiz);

  if (!isAuthenticated) {
    return <Redirect to="/login" />;
  }

  const redirectToPlay = async () => {
    try {
      const response = await axios.get(
        `/enroll_course?course_id=${recommended[0].course_id}&auth_token=${user.token}`
      );
      // history.push(`/play/${id}`);
    } catch (error) {
      Swal.fire({
        title: `${courseDetails.course.title}`,
        text: "Vímos que você ainda não comprou este curso, então não perca tempo, garanta logo a sua vaga no curso.",
        icon: "warning",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Fechar",
        customClass: {
          popup: "dark-mode", // Adiciona a classe 'dark-mode' para o modo escuro
        },
      });
      history.push(`/`);
      return;
    }
  };

  if (movieId) {
    // if (courseDetails) {
    // redirectToPlay();
    // }
  }

  return (
    <>
      <main className="detailPage">
        <div className="detailPage__background">
          <div className="detailPage__overlay"></div>
          <img
            src={
              singleMovie["background_img"]
                ? singleMovie["background_img"]
                : "https://firebasestorage.googleapis.com/v0/b/chromatic-craft-400013.appspot.com/o/images%2Fno-image%2Fdisciplina.jpg?alt=media&token=034ca44e-3b6d-4311-b4b1-a77db31d7aaf&_gl=1*1fe8mzb*_ga*NjMzNTUwOTI5LjE2OTU4NzAwNDc.*_ga_CW55HF8NVT*MTY5NjIxMzMwNC41LjEuMTY5NjIxODg0NS4xNy4wLjA."
            }
          />
        </div>
        <div className="detailPage__info">
          <div className="detailPage__container">
            <h1 className="detailPage__title">{singleMovie["title"]}</h1>
            <div className="detaiPage__buttons">
              <Button
                className="detailPage__playBtn"
                startIcon={<PlayArrowIcon />}
              >
                Assista Agora
              </Button>
              {/* <Button
                className="detailPage__trailerBtn"
                startIcon={<PlayArrowIcon />}
              >
                Trailer
              </Button> */}
              {/* <IconButton size="small" className="detailPage_radialBtn">
                <AddIcon />
              </IconButton> */}
              <IconButton size="small" className="detailPage_radialBtn">
                <ThumbUpAltIcon />
              </IconButton>
              <IconButton size="small" className="detailPage_radialBtn">
                <ThumbDownAltIcon />
              </IconButton>
            </div>
            <div className="detailPage__data">
              <span className="detailPage__duration">
                {/* {singleMovie["runtime"] + " minutes"} */}
                Tempo de conteúdo
              </span>
              <span className="detailPage__years">
                {singleMovie["total_duration"]}
              </span>
              {Array.isArray(singleMovie.genres) == true
                ? singleMovie.genres.map((genre) => (
                    <span className="detailPage__genres">{genre.name}</span>
                  ))
                : null}
            </div>
            <div className="detailPage__description">
              <p>{singleMovie["overview"]}</p>
            </div>
            <AppBar className="detailPage__tabsHeader" position="static">
              <Tabs value={value} onChange={handleChange}>
                <Tab label="Vídeos" />
                <Tab label="Teoria" />
                <Tab label="Atividades" />
              </Tabs>
            </AppBar>
            <TabPanel className="tab__panel" value={value} index={0}>
              <Slider
                className="singleRow singleRow__slider singleRow__recommendedSlider"
                {...sliderConfig}
              >
                {recommended.map((recommendedMovie, index) => (
                  <MovieCardDisciplina
                    id={recommendedMovie.id}
                    key={recommendedMovie.id}
                    title={recommendedMovie.title}
                    poster={
                      recommendedMovie.card_img
                        ? recommendedMovie.card_img
                        : "https://firebasestorage.googleapis.com/v0/b/chromatic-craft-400013.appspot.com/o/images%2Fno-image%2Fcard.jpg?alt=media&token=7c555252-bb79-49c3-863a-3a770d67e6f6&_gl=1*1p34wqx*_ga*NjMzNTUwOTI5LjE2OTU4NzAwNDc.*_ga_CW55HF8NVT*MTY5NjIxMzMwNC41LjEuMTY5NjIxODgxNS40Ny4wLjA."
                    }
                    urlvideo={recommendedMovie.video_url_web}
                    duration={recommendedMovie.duration}
                    watching={true}
                  />
                ))}
              </Slider>
            </TabPanel>
            <TabPanel className="tab__panel" value={value} index={1}>
              {apostilas.length > 0 ? (
                <Slider
                  className="singleRow singleRow__slider singleRow__recommendedSlider"
                  {...sliderConfig}
                >
                  {apostilas.map((arquivoPDF, index) => (
                    <CardAposila
                      id={arquivoPDF.id}
                      key={arquivoPDF.id}
                      title={arquivoPDF.title}
                      poster={
                        arquivoPDF.card_img
                          ? arquivoPDF.card_img
                          : "https://firebasestorage.googleapis.com/v0/b/chromatic-craft-400013.appspot.com/o/images%2Fno-image%2Fcard.jpg?alt=media&token=7c555252-bb79-49c3-863a-3a770d67e6f6&_gl=1*1p34wqx*_ga*NjMzNTUwOTI5LjE2OTU4NzAwNDc.*_ga_CW55HF8NVT*MTY5NjIxMzMwNC41LjEuMTY5NjIxODgxNS40Ny4wLjA."
                      }
                      urlPDF={arquivoPDF.attachment_url}
                      duration={arquivoPDF.duration}
                      watching={true}
                      disciplina={disciplina}
                    />
                  ))}
                </Slider>
              ) : (
                <p>Não encontramos apostilas para este curso</p>
              )}
            </TabPanel>
            <TabPanel className="tab__panel" value={value} index={2}>
              {quiz.length > 0 ? (
                <Slider
                  className="singleRow singleRow__slider singleRow__recommendedSlider"
                  {...sliderConfig}
                >
                  {quiz.map((arquivoPDF, index) => (
                    <CardAtividade
                      id={arquivoPDF.id}
                      key={arquivoPDF.id}
                      title={arquivoPDF.title}
                      poster={
                        arquivoPDF.card_img
                          ? arquivoPDF.card_img
                          : "https://firebasestorage.googleapis.com/v0/b/chromatic-craft-400013.appspot.com/o/images%2Fno-image%2Fcard.jpg?alt=media&token=7c555252-bb79-49c3-863a-3a770d67e6f6&_gl=1*1p34wqx*_ga*NjMzNTUwOTI5LjE2OTU4NzAwNDc.*_ga_CW55HF8NVT*MTY5NjIxMzMwNC41LjEuMTY5NjIxODgxNS40Ny4wLjA."
                      }
                      urlPDF={arquivoPDF.attachment_url}
                      duration={arquivoPDF.duration}
                      watching={true}
                      disciplina={disciplina}
                    />
                  ))}
                </Slider>
              ) : (
                <p>Não encontramos apostilas para este curso</p>
              )}
              {/*<div className="tab__container">
                <div className="tab__mainColumn">
                  <h2 className="tab__title">{singleMovie["title"]}</h2>
                  <p className="tab__description">{singleMovie["overview"]}</p>
                </div>
                <div className="tab__itemsColumn">
                  <div className="tab_itemSubColumn">
                    <div className="tab__item">
                      <h3 className="tab__subtitle">Duration:</h3>
                      <p>{singleMovie["runtime"]} minutes</p>
                    </div>
                    <div className="tab__item">
                      <h3 className="tab__subtitle">Release Date:</h3>
                      <p>{singleMovie["release_date"]}</p>
                    </div>
                  </div>
                  <div className="tab__itemSubColumn">
                    <div className="tab__item">
                      <h3 className="tab__subtitle">Genres:</h3>
                      {Array.isArray(singleMovie.genres) == true
                        ? singleMovie.genres.map((genre) => <p>{genre.name}</p>)
                        : null}
                    </div>
                    <div className="tab__item">
                      <h3 className="tab__subtitle">Cast:</h3>
                      {cast.slice(0, 4).map((actor) => (
                        <p>{actor.name}</p>
                      ))}
                    </div>
                  </div>
                </div>
              </div>*/}
            </TabPanel>
          </div>
        </div>
      </main>
    </>
  );
};

export default withRouter(DetailPage);
