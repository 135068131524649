import React from "react";
import Navbar from "./../Navbar";
import { useAuth } from "../../providers/auth";
import UserSelector from "./../UserSelector";
import "./Header.css";

const Header = ({ className }) => {
  const { user } = useAuth();
  return (
    <header className={className}>
      <Navbar />
      {user.token ? <UserSelector /> : null}
    </header>
  );
};

export default Header;
