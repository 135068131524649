import React, { useState, useEffect } from "react";
import { apiKey } from "../../utils";
import axios from "axios";
import { useHistory } from "react-router-dom";
import StepWizard from "react-step-wizard";
import Swal from "sweetalert2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"; // Importe o FontAwesome
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons"; // Importe os ícones necessários
import { SpinnerCircular } from "spinners-react";
import { termos } from "../../utils";
import "./Signature.css";
import { useAuth } from "../../providers/auth";

const api_url = process.env.REACT_APP_API_URL;

const Assinatura = () => {
  const { user, setUser } = useAuth();
  const history = useHistory();
  const [isloading, setIsloading] = useState(null);
  const [isValidateCode, setIsValidateCode] = useState(null);
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [cupomData, setCupomData] = useState(null);
  const [receivedEmail, setReceivedEmail] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [cupomError, setCupomError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [msg, setMsg] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validatePassword = (password) => {
    const passwordRegex =
      /^(?=.*[A-Za-z])(?=.*\d|[!@#$%^&*])[A-Za-z\d!@#$%^&*]{6,}$/;
    return passwordRegex.test(password);
  };

  const [userData, setUserData] = useState({
    email: "",
    cupom: "",
    receivedEmail: "",
    password: "",
  });

  const buscaServidor = async (mat) => {
    const url = "https://apifaspm-appeduca.vercel.app/buscaservidor";
    const payload = {
      matricula: mat,
      cpf: "",
    };

    try {
      const response = await axios.post(url, payload);
      return response.data;
    } catch (error) {
      console.error("Erro ao chamar o endpoint:", error);
      throw error; // Re-throw para que o erro possa ser tratado no componente que chama essa função, se desejado.
    }
  };

  const handleSubmit = async (e) => {
    const { email, cupom, password, receivedEmail, matricula } = userData;
    setIsloading(true);
    try {
      const fetchCupomData = async (code) => {
        try {
          setIsloading(true);
          const response = await axios.get(
            `${api_url}/validate_cupom?code=${code}`
          );
          // setCupomData(response.data);
          const _cupom = response.data;

          if (_cupom.error === 0) {
            setIsloading(false);
            Swal.fire({
              title: "Assinatura",
              text: "Cupom inválido, por favor insira um cupom válido.",
              icon: "warning",
              showCancelButton: false,
              confirmButtonColor: "#3085d6",
              confirmButtonText: "Fechar",
              customClass: {
                popup: "dark-mode", // Adiciona a classe 'dark-mode' para o modo escuro
              },
            });
            return;
          } else {
            if (_cupom.tot_cupons_usados < _cupom.qtd) {
              const data = JSON.stringify({
                email: email,
                password: password,
                cupom: cupom,
                id_cupom: _cupom.id,
                course_id: _cupom.course_id,
                // matricula: matricula,
              });

              const config = {
                method: "post",
                maxBodyLength: Infinity,
                url: `${api_url}/registrar`,
                headers: {
                  "Content-Type": "application/json",
                },
                data: data,
              };

              const response = await axios.request(config);
              if (response.status === 200) {
                setIsloading(false);
                history.push("/login");
              } else {
                setIsloading(false);
                Swal.fire({
                  title: "Assinatura",
                  text: response.statusText,
                  icon: "warning",
                  showCancelButton: false,
                  confirmButtonColor: "#3085d6",
                  confirmButtonText: "Fechar",
                  customClass: {
                    popup: "dark-mode", // Adiciona a classe 'dark-mode' para o modo escuro
                  },
                });
              }
            } else {
              // setCupomError("Por favor, insira um CUPOM válido.");
              setIsloading(false);
              Swal.fire({
                title: "Assinatura",
                text: "Este cupom não é mas váldo, por favor insira um cupom válido.",
                icon: "warning",
                showCancelButton: false,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "Fechar",
                customClass: {
                  popup: "dark-mode", // Adiciona a classe 'dark-mode' para o modo escuro
                },
              });
              return;
            }
          }
        } catch (error) {
          setIsloading(false);
          console.error("Erro ao buscar o cupom:", error);
        }
      };

      if (cupom.length > 0) {
        setIsloading(true);
        fetchCupomData(cupom);
      } else {
        setIsloading(true);
        const data = JSON.stringify({
          email: email,
          password: password,
        });

        const config = {
          method: "post",
          maxBodyLength: Infinity,
          url: `${api_url}/registrar`,
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        };

        const response = await axios.request(config);
        if (response.status === 200) {
          // console.log('Mensagem de OK:', JSON.stringify(response.data));
          history.push("/login");
        } else {
          Swal.fire({
            title: "Assinatura",
            text: response.statusText,
            icon: "warning",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Fechar",
            customClass: {
              popup: "dark-mode", // Adiciona a classe 'dark-mode' para o modo escuro
            },
          });
        }
      }

      setIsloading(false);
    } catch (error) {
      console.error(error);
      setIsloading(false);
    }

    // Aqui você pode adicionar a lógica para enviar os dados do usuário
    // console.log("Dados do usuário:", email, cupom, password, receivedEmail);
  };

  // Componente para a Etapa 1 (Digite seu e-mail)
  const Step1 = ({ nextStep }) => {
    const [email, setEmail] = useState("");
    const [cupom, setCupom] = useState("");
    const [matricula, setMatricula] = useState("");

    const handleNext = () => {
      // if (!matricula) {
      //   return;
      // }

      if (!email) {
        setEmailError("Por favor, insira um e-mail válido.");
        return;
      } else {
        if (validateEmail(email)) {
          setEmailError("");
          setUsername(email);
          setUserData({
            ...userData,
            email: email,
            cupom: cupom,
            matricula: matricula,
            receivedEmail: receivedEmail,
          });
        } else {
          setEmailError("Por favor, insira um e-mail válido.");
          return;
        }
      }
      nextStep();
    };

    return (
      <div>
        <p
          className="text-color--primary text-size-12 space-text-etapas"
          data-testid="onboarding-stepper-1-of-4"
          data-overline="printable"
        >
          PASSO 1 DE 4
        </p>
        <h3 className="text-input-title-form">Digite seu e-mail</h3>
        <div className="space-text">
          <span>
            <p class="text-color--primary" style={{ display: "inline" }}>
              Você usará esse e-mail e senha para entrar na conta do Sectet On e
              assistir suas aulas e cursos preferidos.
            </p>
          </span>
        </div>

        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          name="firstName"
          placeholder="Seu e-mail"
          required
        />
        {emailError && (
          <div
            style={{
              color: "red",
              fontSize: "12.5px",
              marginBottom: "10px",
              marginTop: "-10px",
            }}
          >
            {emailError}
          </div>
        )}
        <input
          type="text"
          value={cupom}
          onChange={(e) => setCupom(e.target.value)}
          style={{ textTransform: "uppercase" }}
          maxLength={20} // Limita a 20 caracteres
          placeholder="Cupom"
        />
        {cupomError && (
          <div
            style={{
              color: "red",
              fontSize: "12.5px",
              marginBottom: "10px",
              marginTop: "-10px",
            }}
          >
            {cupomError}
          </div>
        )}
        {/* {isValidateCode && ( */}
        {/* <input
          type="text"
          value={matricula}
          onChange={(e) => setMatricula(e.target.value)}
          maxLength={50} // Limita a 20 caracteres
          placeholder="Matrícula funcional"
        /> */}
        {/* )} */}

        <label style={{ display: "inline" }}>
          <input
            type="checkbox"
            checked={receivedEmail}
            onChange={() => setReceivedEmail(!receivedEmail)}
            className="check-termos"
          />
          <div className="text-color--primary text-size-12 label-check">
            Sim! Quero receber notícias, ofertas especiais e outras informações
            do Sectet On e das empresas parceiras.
          </div>
        </label>
        <textarea
          value={termos[1].text}
          placeholder="Digite o contrato aqui"
          readOnly={true}
          className="text-termos space-termos"
        />
        <button
          type="button"
          aria-label="Assine"
          className="button-login"
          style={{ display: "inline-block" }}
          onClick={handleNext}
        >
          <b>Continuar</b>
        </button>
      </div>
    );
  };

  // Componente para a Etapa 2 (Contrato de Assinatura)
  const Step2 = ({ nextStep }) => {
    const handleNext = () => {
      // Validar o contrato, se necessário
      nextStep();
    };

    return (
      <div>
        <p
          className="text-color--primary text-size-12 space-text-etapas"
          data-testid="onboarding-stepper-1-of-4"
          data-overline="printable"
        >
          PASSO 2 DE 4
        </p>
        <h3 className="text-input-title-form">Contrato de Assinatura</h3>
        {/* <div className="space-text"></div> */}
        <input type="text" className="input-hidden" disabled />
        <textarea
          name="contract"
          value={termos[0].text}
          // onChange={(e) => setContract(e.target.value)}
          className="text-termos space-termos"
          readOnly={true}
        />
        <p className="text-color--primary space-text text-size-12 center">
          Ao clicar em "Concordar e Continuar", você confirma que leu o nosso
          Contrato de Assinatura na íntegra e concorda com ele.
        </p>
        <button
          type="button"
          aria-label="Assine"
          className="button-login"
          style={{ display: "inline-block" }}
          onClick={handleNext}
        >
          <b>Concordar e continuar</b>
        </button>
      </div>
    );
  };

  const Step3 = ({ nextStep }) => {
    // const Step3 = () => {
    const [password, setPassword] = useState("");

    const handleTogglePassword = () => {
      setShowPassword(!showPassword);
    };

    const handleNext = () => {
      if (!password) {
        setPasswordError("Por favor, insira uma senha.");
        return;
      } else {
        if (validatePassword(password)) {
          setPasswordError("");
          setUserData({
            ...userData,
            password: password,
          });
        } else {
          setPasswordError(
            "A senha deve ter no mínimo seis caracteres, com distinção entre maiúsculas e minúsculas, e conter pelo menos um número ou caractere especial."
          );
          return;
        }
      }

      // Validar a senha, se necessário
      nextStep(); // Se quiser avançar para a próxima etapa
    };

    return (
      <div>
        <p
          className="text-color--primary text-size-12 space-text-etapas"
          data-testid="onboarding-stepper-1-of-4"
          data-overline="printable"
        >
          PASSO 3 DE 4
        </p>
        <h3 className="text-input-title-form">Crie uma senha</h3>
        <div className="space-text">
          <span>
            <p class="text-color--primary" style={{ display: "inline" }}>
              Você usará esse e-mail e senha para entrar na conta do Sectet On e
              assistir suas aulas e cursos preferidos.
            </p>
          </span>
        </div>
        <div style={{ position: "relative" }}>
          <input
            type={showPassword ? "text" : "password"}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Sua senha"
          />
          <button
            type="button"
            onClick={handleTogglePassword}
            style={{
              position: "absolute",
              top: "35%",
              right: "20px",
              transform: "translateY(-50%)",
              background: "none",
              border: "none",
              cursor: "pointer",
            }}
          >
            <FontAwesomeIcon
              color="#fff"
              icon={showPassword ? faEyeSlash : faEye}
            />
          </button>
        </div>
        {passwordError && (
          <div
            style={{
              color: "red",
              fontSize: "12.5px",
              marginBottom: "10px",
              marginTop: "-10px",
            }}
          >
            {passwordError}
          </div>
        )}
        <span className="text-color--primary space-text text-size-12">
          Use no mínimo seis caracteres (com distinção entre maiúsculas e
          minúsculas) com pelo menos um número ou caractere especial.
        </span>
        <br />
        <br />
        <div class="email-confirmed">
          <p class="body-copy margin--0 text-color--section-header">
            Você vai usar este e-mail para entrar:
          </p>
          <p class="margin--0 body-copy body-copy--large text-color--primary margin--0">
            {username}
          </p>
        </div>
        <button
          type="button"
          aria-label="Assine"
          className="button-login"
          style={{ display: "inline-block" }}
          onClick={handleNext}
        >
          <b>Continuar</b>
        </button>
      </div>
    );
  };

  // Componente para a Etapa 4 (Escolha um plano de assinatura)
  const Step4 = () => {
    const handlePayment = (method) => {
      // Implemente o pagamento aqui (PIX ou Cartão de crédito)
      console.log(`Você escolheu o método de pagamento: ${method}`);
    };

    return (
      <div>
        {/* <h2>Escolha um plano de assinatura</h2>
        <div>
          <label>
            <input type="radio" name="plano" value="mensal" />
            Plano Mensal
          </label>
          <label>
            <input type="radio" name="plano" value="anual" />
            Plano Anual
          </label>
        </div>
        <button onClick={() => handlePayment("PIX")}>Pagar com PIX</button>
        <button onClick={() => handlePayment("Cartão de crédito")}>
          Pagar com Cartão de crédito
        </button> */}
        <p
          className="text-color--primary text-size-12 space-text-etapas"
          data-testid="onboarding-stepper-1-of-4"
          data-overline="printable"
        >
          PASSO 4 DE 4
        </p>
        <h3 className="text-input-title-form">Finalizar Assinatura</h3>
        <div className="space-text">
          <span>
            <p class="text-color--primary" style={{ display: "inline" }}>
              Obrigado por escolher a plataforma Sectet On, agora é só desfrutar
              de cursos e conteúdos de alta qualidade. Clique no botão abaixo
              para concluir a abertura de sua conta.
            </p>
          </span>
        </div>
        <button
          type="button"
          aria-label="Assine"
          className="button-login"
          style={{ display: "inline-block" }}
          onClick={handleSubmit}
        >
          {isloading ? (
            <SpinnerCircular
              color="rgba(255, 255, 255, 1)"
              secondaryColor="rgba(0, 0, 0, 0.44)"
              size={20}
            />
          ) : (
            <b>Finalizar Assinatura</b>
          )}
        </button>
      </div>
    );
  };

  return (
    <main className="Signature-container">
      {/* <form onSubmit={handleSubmit} className="Signature-form"> */}
      <form onSubmit={handleSubmit} className="Signature-form">
        <StepWizard>
          <Step1 />
          <Step2 />
          <Step3 />
          <Step4 />
          {/* <Step1 email={username} />
          <Step2 contract={password} />
          <Step3 password={password} />
          <Step4 /> */}
        </StepWizard>
      </form>
    </main>
  );
};

export default Assinatura;
