import React, { useState, useEffect, useRef } from "react";
import { apiKey } from "../../utils";
import axios from "axios";
import useSearch from "../../hooks/useSearch";
// import { Document, Page } from "mgr-pdf-viewer-react";
import PDFViewer from "mgr-pdf-viewer-react";
import { Button } from "@material-ui/core";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import ZoomInIcon from "@material-ui/icons/ZoomIn";
import ZoomOutIcon from "@material-ui/icons/ZoomOut";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { useHistory, useLocation } from "react-router-dom";
import { useAuth } from "../../providers/auth";
import "./PdfPage.css";

const api_url = process.env.REACT_APP_API_URL;

const PdfPage = () => {
  const location = useLocation();
  const history = useHistory();
  const { user } = useAuth();
  const [pageNumber, setPageNumber] = useState(1);
  const [numPages, setNumPages] = useState(null);
  const [documentHeight, setDocumentHeight] = useState("auto");
  const documentRef = useRef(null);
  const [zoom, setZoom] = useState(1.5);
  const id = location.pathname.split("/").pop();
  const urlPDF = location.state.urlPDF;
  const title = location.state.title;
  const disciplina = location.state.disciplina;

  const isAuthenticated = !!user.token;

  if (!isAuthenticated) {
    history.push("/login");
  }

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    if (documentRef.current) {
      documentRef.current.scrollTo(0, 0); // Rola para o topo
    }
  };

  useEffect(() => {
    setDocumentHeight("auto");
  }, [pageNumber]);

  const goToPreviousPage = () => {
    if (pageNumber > 1) setPageNumber(pageNumber - 1);
  };

  const goToNextPage = () => {
    if (pageNumber < numPages) setPageNumber(pageNumber + 1);
  };

  const zoomIn = () => {
    setZoom(zoom + 0.1);
  };

  const zoomOut = () => {
    setZoom(zoom - 0.1);
  };

  const handleGoBack = () => {
    history.goBack();
  };

  const CustomPages = (props) => {
    const { page, pages } = props;
    setNumPages(pages);
    return (
      <h6 style={{ display: "inline-block", marginTop: 0, fontSize: "10px" }}>
        Página {page} de {pages}
      </h6>
    );
  };

  return (
    <div className="pdf-reader-container">
      <div className="pdf-viewer" ref={documentRef}>
        <Button
          className="detailPage__trailerBtn back-button"
          startIcon={<ArrowBackIosIcon />}
          onClick={handleGoBack}
        >
          Voltar
        </Button>
        <br/>
        <br/>
        <h1 className="discicplina text-white">{disciplina}</h1>
        <h4 className="conteudo text-white">{title}</h4>
        <div className="pdf-controls">
          <Button
            onClick={zoomOut}
            variant="outlined"
            style={{ color: "#fff" }}
          >
            <ZoomOutIcon />
          </Button>
          <Button
            onClick={goToPreviousPage}
            variant="outlined"
            style={{ color: "#fff" }}
          >
            <NavigateBeforeIcon />
          </Button>
          <span className="text-white">
            Página {pageNumber} de {numPages}
          </span>
          <Button
            onClick={goToNextPage}
            variant="outlined"
            style={{ color: "#fff" }}
          >
            <NavigateNextIcon />
          </Button>
          <Button onClick={zoomIn} variant="outlined" style={{ color: "#fff" }}>
            <ZoomInIcon />
          </Button>
        </div>
        <div className="pdf-viewer">
          <PDFViewer
            document={{
              url: urlPDF,
            }}
            loader={
              <h5
                style={{
                  color: "#fff",
                  marginTop: "250px",
                  marginBottom: "250px",
                }}
              >
                Estamos carregando sua apostila, aguarde...
              </h5>
            }
            scale={zoom}
            page={pageNumber}
            // hideNavbar={true}
            navigation={{
              elements: {
                pages: CustomPages,
              },
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default PdfPage;
