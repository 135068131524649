import React from "react";
import "./Footer.css";
const Footer = () => {
  return (
    <footer>
      <p>
      © 2024 Sectet On
      Produto comercializado por Secretaria de Estado de Ciência, Tecnologia e Educação Superior Profissional e Tecnológica do Pará.
      Endereço: Av. Conselheiro Furtado, 2520, Bairro da Cremação, Belém, Pará – CEP  66063-060,
      Todos os direitos reservados, todas as imagens, conteúdos, vídeos e programas nesta plataforma são marcas 
      registradas da SECTET, e qualquer duplicação e/ou cópia são estritamente proibidas.
      </p>
      <img
        id="logofooter" 
        src="https://firebasestorage.googleapis.com/v0/b/chromatic-craft-400013.appspot.com/o/images%2Fslides%2Fsectet%2Flogo2022.png?alt=media&token=4e4defee-af93-419c-88ed-5aa95191ff88" 
        alt="EducaFlix"/>
    </footer>
  );
};

export default Footer;
