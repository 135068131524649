import React, { useState } from "react";
import axios from "axios";
import { useAuth } from "../../providers/auth";
import { useHistory } from "react-router-dom";
import { SpinnerCircular } from "spinners-react";
import IconButton from "@material-ui/core/IconButton";
import AddAPhotoIcon from "@material-ui/icons/AddAPhoto";
import Swal from "sweetalert2";
import "./ProfilePage.css";

const api_url = process.env.REACT_APP_API_URL;

const ProfilePage = () => {
  const { user, setUser } = useAuth();
  const history = useHistory();
  const videoId = "1CDmBzWgbQqBkgv2Dqt2WJMf_1XUcHJO3";
  const [isloading, setIsloading] = useState(null);
  const [imagem, setImagem] = useState(null);

  const isAuthenticated = !!user.token;

  if (!isAuthenticated) {
    history.push("/login");
  }

  const handleUpload = async (event) => {
    const arquivo = event.target.files[0];
    const reader = new FileReader();

    reader.onload = () => {
      setImagem(reader.result);
    };

    reader.readAsDataURL(arquivo);

    // Código de upload
    const formData = new FormData();
    formData.append("auth_token", user.token);
    formData.append("file", event.target.files[0]);

    try {
      const response = await axios.post(
        `${api_url}/upload_user_image`,
        formData
      );

      if (response.data.status === "success") {
        Swal.fire({
          title: 'Perfil',
          text: 'Sua foto de perfil foi atualizada.',
          icon: 'success',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Fechar',
          customClass: {
            popup: 'dark-mode', // Adiciona a classe 'dark-mode' para o modo escuro
          },
        });
      } else {
        Swal.fire({
          title: 'Perfil',
          text: 'Falha ao tentar atualizar sua foto de perfil, tente novamente mais tarde.',
          icon: 'error',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Fechar',
          customClass: {
            popup: 'dark-mode', // Adiciona a classe 'dark-mode' para o modo escuro
          },
        });
      }
    } catch (error) {
      console.error("Erro na requisição:", error);
    }
  };

  const handleIconClick = () => {
    document.getElementById("selecionar-imagem").click();
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUser((prevUser) => ({
      ...prevUser,
      [name]: value,
    }));
  };

  const handleUpdateProfile = async () => {
    // console.log("Dados atualizados:", user);
    setIsloading(true);
    try {
      const data = JSON.stringify({
        user,
      });

      const config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${api_url}/update_userdata`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      const response = await axios.request(config);
      if (response.status === 200) {
        if (response.data.status !== "failed") {
          Swal.fire({
            title: "Perfil",
            text: "O seu perfil atualizado com sucesso.",
            icon: "success",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Fechar",
            customClass: {
              popup: "dark-mode", // Adiciona a classe 'dark-mode' para o modo escuro
            },
          });
        } else {
          Swal.fire({
            title: "Perfil",
            text: `Erro: ${response.data.error_reason}`,
            icon: "warning",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Fechar",
            customClass: {
              popup: "dark-mode", // Adiciona a classe 'dark-mode' para o modo escuro
            },
          });
        }
      } else {
        Swal.fire({
          title: "Perfil",
          text: `Erro: ${response.statusText}`,
          icon: "warning",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Fechar",
          customClass: {
            popup: "dark-mode", // Adiciona a classe 'dark-mode' para o modo escuro
          },
        });
      }
      setIsloading(false);
      console.log(response.data);
    } catch (error) {
      console.error(error);
      setIsloading(false);
    }
  };

  return (
    <div className="profile-container">
      <div className="profile-container-form">
        <div className="profile-header">
          {/* <img src={user.image} alt="Avatar" className="avatar" /> */}

          <div style={{ position: "relative", display: "inline-block" }}>
            {imagem && (
              <div style={{ position: "relative", display: "inline-block" }}>
                <img
                  src={imagem ? imagem : user.image}
                  alt=""
                  className="avatar"
                />
                <div
                  onClick={handleIconClick}
                  style={{
                    position: "absolute",
                    bottom: "-45px",
                    left: "-15px",
                    width: "150px",
                    height: "40px",
                    cursor: "pointer",
                    background: "rgba(255,255,255,0.8)",
                    borderRadius: "10px",
                    padding: "10px",
                  }}
                >
                  <span style={{ marginRight: "5px" }}>Atualizar Foto</span>{" "}
                  <AddAPhotoIcon fontSize="inherit" />
                  <input
                    type="file"
                    accept="image/*"
                    id="selecionar-imagem"
                    style={{ display: "none" }}
                    onChange={handleUpload}
                  />
                </div>
              </div>
            )}
            {!imagem && (
              <div>
                <img src={user.image} alt="Avatar" className="avatar" />
                <div
                  onClick={handleIconClick}
                  style={{
                    position: "absolute",
                    bottom: "-45px",
                    left: "-15px",
                    width: "150px",
                    height: "40px",
                    cursor: "pointer",
                    background: "rgba(255,255,255,0.8)",
                    borderRadius: "10px",
                    padding: "10px",
                  }}
                >
                  <span style={{ marginRight: "5px" }}>Atualizar Foto</span>{" "}
                  <AddAPhotoIcon fontSize="inherit" />
                  <input
                    type="file"
                    accept="image/*"
                    id="selecionar-imagem"
                    style={{ display: "none" }}
                    onChange={handleUpload}
                  />
                </div>
              </div>
            )}
          </div>
        </div>

        <br />
        <br />
        <div className="profile-details">
          <input
            type="text"
            name="first_name"
            value={user.first_name}
            onChange={handleInputChange}
            placeholder="Nome"
          />
          <input
            type="text"
            name="last_name"
            value={user.last_name}
            onChange={handleInputChange}
            placeholder="Sobrenome"
          />
          <input
            type="text"
            name="email"
            value={user.email}
            // onChange={handleInputChange}
            placeholder="E-mail"
            disabled
          />
          <input
            type="date"
            name="datanascimento"
            value={user.datanascimento}
            onChange={handleInputChange}
            placeholder="Data de Nascimento"
          />
          <select
            name="sexo"
            value={user.sexo}
            onChange={handleInputChange}
            placeholder="Sexo"
            style={{
              color: "#fff",
              marginBottom: "20px",
              padding: "10px",
              backdropFilter: "blur(50px)",
              backgroundColor: "rgb(49, 52, 62)",
              border: "1px solid transparent",
              borderRadius: "4px",
              caretColor: "rgb(108, 18, 211)",
              height: "48px",
              outline: "none",
              transition: "all 0.1s ease 0s",
              width: "100%",
            }}
          >
            <option value="">Selecione o sexo</option>
            <option value="M" selected={user.sexo === "M"}>
              Masculino
            </option>
            <option value="F" selected={user.sexo === "F"}>
              Feminino
            </option>
            <option value="O" selected={user.sexo === "O"}>
              Outros
            </option>
          </select>
          <input
            type="text"
            name="telefone"
            value={user.telefone}
            onChange={handleInputChange}
            placeholder="(00) 00000-0000"
          />
          <input
            type="text"
            name="endereco"
            value={user.endereco}
            onChange={handleInputChange}
            placeholder="Seu endereço"
          />
          <div className="profile-details">
            {/* ... (inputs dos campos) */}
            <button className="button-login" onClick={handleUpdateProfile}>
              {isloading ? (
                <SpinnerCircular
                  color="rgba(255, 255, 255, 1)"
                  secondaryColor="rgba(0, 0, 0, 0.44)"
                  size={20}
                />
              ) : (
                "Atualizar Perfil"
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfilePage;
