import React, { useState } from "react";
import { apiKey } from "../../utils";
import axios from "axios";
import useSearch from "../../hooks/useSearch";
import { useHistory } from "react-router-dom";
import { SpinnerCircular } from "spinners-react";
import Swal from 'sweetalert2';
import "./RecoveryPassword.css";
import { useAuth } from "../../providers/auth";

const api_url = process.env.REACT_APP_API_URL;

const RecoveryPassword = () => {
  const { user, setUser } = useAuth();
  const history = useHistory();
  const [username, setUsername] = useState("");
  const [isloading, setIsloading] = useState(null);

  const recuperaSenha = async (email, password) => {
    try {
      const formData = new FormData();
      formData.append('email', email);

      const response = await axios.post(
        `${api_url}/forgot_password`, formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      if (response.status === 200) {
        return { success: true, data: response.data };
      } else {
        return { success: false, message: `Erro: ${response.statusText}` };
      }
    } catch (error) {
      return {
        success: false,
        message: "Erro ao tentae recuperar a senha. Verifique o e-mail informado.",
      };
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsloading(true);
    try {
      const response = await recuperaSenha(username);
      if (response.success) {
        if (response.data.validity) {
          Swal.fire({
            title: 'Recuperação de senha',
            text: response.data.message,
            icon: 'success',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Fechar',
            customClass: {
              popup: 'dark-mode', // Adiciona a classe 'dark-mode' para o modo escuro
            },
          });
          setIsloading(false);
          history.push("/login");
        } else {
          setIsloading(false);
          Swal.fire({
            title: 'Recuperação de senha',
            text: 'Falha ao tentar recuperar senha, favor verificar o e-mail informado.',
            icon: 'error',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Fechar',
            customClass: {
              popup: 'dark-mode', // Adiciona a classe 'dark-mode' para o modo escuro
            },
          });
        }
        // console.log('Login bem-sucedido:', response.data);
      } else {
        setIsloading(false);
        Swal.fire({
          title: 'Recuperação de senha',
          text: 'Falha ao tentar recuperar senha, favor verificar o e-mail informado.',
          icon: 'error',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Fechar',
          customClass: {
            popup: 'dark-mode', // Adiciona a classe 'dark-mode' para o modo escuro
          },
        });
      }
    } catch (error) {
      setIsloading(false);
      Swal.fire({
        title: 'Recuperação de senha',
        text: `Erro ao tentar recuperar a senha: ${error}`,
        icon: 'error',
        showCancelButton: false,
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Fechar',
        customClass: {
          popup: 'dark-mode', // Adiciona a classe 'dark-mode' para o modo escuro
        },
      });
    }
  };

  return (
    <main className="login-container">
      <form onSubmit={handleSubmit} className="login-form">
        <h3 class="text-input-title-form">Confirme o seu e-mail</h3>
        <div class="space-text">
          <span>
            <p class="text-color--primary" style={{ display: "inline" }}>
              Precisamos verificar seu endereço de e-mail. Enviaremos um e-mail com um linke de redefinição de senha que expira em 15 minutos.
            </p>
          </span>
        </div>
        <input
          type="text"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          placeholder="E-mail"
          required
        />
        <button className="button-login" type="submit">
          {isloading ? (
            <SpinnerCircular
              color="rgba(255, 255, 255, 1)"
              secondaryColor="rgba(0, 0, 0, 0.44)"
              size={20}
            />
          ) : (
            "Confirmar"
          )}
        </button>
        <div className="space-text-recovery-text">
          <p class="text-color--primary" style={{ display: "inline-block" }}>
            Não recebeu o e-mail? Verifique sua caixa de spam ou lixo eletrônico.
          </p>
        </div>
      </form>
    </main>
  );
};

export default RecoveryPassword;
