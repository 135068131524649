import React, { useState, useEffect } from "react";
import { apiKey } from "../../utils";
import axios from "axios";
import useSearch from "../../hooks/useSearch";
import { useLocation, useHistory } from "react-router-dom";
import { SpinnerCircular } from "spinners-react";
import Swal from "sweetalert2";
import "./ChangePassword.css";
import { useAuth } from "../../providers/auth";

const api_url = process.env.REACT_APP_API_URL;

const ChangePassword = () => {
  const { user, setUser } = useAuth();
  const location = useLocation();
  const history = useHistory();
  const [new_password, setNew_password] = useState("");
  const [confirm_password, setConfirm_password] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const [isloading, setIsloading] = useState(null);

  const trocarSenha = async (new_password, confirm_password, verificationCode) => {
    try {
      const formData = new FormData();
      formData.append("code", verificationCode);
      formData.append("new_password", new_password);
      formData.append("confirm_password", confirm_password);
      const response = await axios.post(`${api_url}/change_password`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (response.status === 200) {
        return { success: true, data: response.data };
      } else {
        return { success: false, message: `Erro: ${response.statusText}` };
      }
    } catch (error) {
      return {
        success: false,
        message: "Erro ao tentar tocar a senha. Tente novamente mais tarde.",
      };
    }
  };

  // Função para validar o código de verificação no lado do cliente
  const validateVerificationCode = async (verificationCode) => {
    try {
      const formData = new FormData();
      formData.append("code", verificationCode);

      const response = await axios.post(
        `${api_url}/change_password`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data.validity !== true) {
        Swal.fire({
          title: "Troca de senha",
          text: response.data.message,
          icon: "warning",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Fechar",
          customClass: {
            popup: "dark-mode", // Adiciona a classe 'dark-mode' para o modo escuro
          },
        });
        history.push("/");
      }
    } catch (error) {
      Swal.fire({
        title: "Troca de senha",
        text: "Erro ao tentar trocar a senha, tente novamente mais tarde.",
        icon: "warning",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Fechar",
        customClass: {
          popup: "dark-mode", // Adiciona a classe 'dark-mode' para o modo escuro
        },
      });
    }
  };

  useEffect(() => {
    const parts = location.pathname.split("/");
    setVerificationCode(parts[parts.length - 1])
    const vCode = parts[parts.length - 1];

    if (vCode) {
      validateVerificationCode(vCode);
    } else {
      console.error("Verification code not found in the URL", vCode);
    }
  }, [location.pathname]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsloading(true);
    try {
      const response = await trocarSenha(new_password, confirm_password, verificationCode);
      if (response.success) {
        if (response.data.validity) {
          setIsloading(false);
          Swal.fire({
            title: "Troca de senha",
            text: response.data.message,
            icon: "success",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Fechar",
            customClass: {
              popup: "dark-mode", // Adiciona a classe 'dark-mode' para o modo escuro
            },
          });
          history.push("/login");
        } else {
          setIsloading(false);
          Swal.fire({
            title: "Login",
            text: "Falha no login, favor corrigir as credenciais.",
            icon: "warning",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Fechar",
            customClass: {
              popup: "dark-mode", // Adiciona a classe 'dark-mode' para o modo escuro
            },
          });
        }
        // console.log('Login bem-sucedido:', response.data);
      } else {
        setIsloading(false);
        Swal.fire({
          title: "Login",
          text: "Falha no login, favor corrigir as credenciais.",
          icon: "warning",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Fechar",
          customClass: {
            popup: "dark-mode", // Adiciona a classe 'dark-mode' para o modo escuro
          },
        });
      }
    } catch (error) {
      setIsloading(false);
      Swal.fire({
        title: "Login",
        text: `Erro ao fazer login: ${error}`,
        icon: "error",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Fechar",
        customClass: {
          popup: "dark-mode", // Adiciona a classe 'dark-mode' para o modo escuro
        },
      });
    }
  };

  const handleEntrar = () => {
    history.push("/login");
  };

  return (
    <main className="login-container">
      <form onSubmit={handleSubmit} className="login-form">
        <h3 class="text-input-title-form">Trocar senha</h3>
        <div class="space-text">
          <span>
            <p class="text-color--primary" style={{ display: "inline" }}>
              Você precisará informar uma nova senha e confirmar a senha de acesso.
            </p>
          </span>
        </div>
        <input
          type="text"
          value={new_password}
          onChange={(e) => setNew_password(e.target.value)}
          placeholder="Nova senha"
          required
        />
        <input
          type="password"
          value={confirm_password}
          onChange={(e) => setConfirm_password(e.target.value)}
          placeholder="Confirmação de senha"
          required
        />
        <button className="button-login" type="submit">
          {isloading ? (
            <SpinnerCircular
              color="rgba(255, 255, 255, 1)"
              secondaryColor="rgba(0, 0, 0, 0.44)"
              size={20}
            />
          ) : (
            "CONFIRMAR"
          )}
        </button>
        <div className="space-text-assine">
          <p class="text-color--primary" style={{ display: "inline-block" }}>
            Já tem conta?
          </p>
          <button
            type="button"
            aria-label="Assine"
            className="link-assine text-color--primary"
            style={{ display: "inline-block" }}
            onClick={handleEntrar}
          >
            <b>Entrar</b>
          </button>
        </div>
      </form>
    </main>
  );
};

export default ChangePassword;
