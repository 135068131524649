import React from "react";
import Body from "./body/Body"

export default function Curso() {
  return (
    <>
      <section
        className="course-header-area"
        style={{
          boxSizing: "border-box",
          padding: "60px 0px",
          backgroundColor: "rgb(41, 48, 59)",
          color: "rgb(255, 255, 255)",
        }}
      >
        <div
          className="container"
          style={{
            boxSizing: "border-box",
            width: "100%",
            paddingRight: "var(--bs-gutter-x,.75rem)",
            paddingLeft: "var(--bs-gutter-x,.75rem)",
            marginRight: "auto",
            marginLeft: "auto",
            maxWidth: "1320px",
            fontFamily: '"Open Sans", sans-serif',
          }}
        >
          <div
            className="row align-items-end"
            style={{
              boxSizing: "border-box",
              display: "flex",
              flexWrap: "wrap",
              marginTop: "calc(0 * -1)",
              marginRight: "calc(1.5rem * -.5)",
              marginLeft: "calc(1.5rem * -.5)",
              fontFamily: '"Open Sans", sans-serif',
              alignItems: "flex-end",
            }}
          >
            <div
              className="col-lg-8"
              style={{
                boxSizing: "border-box",
                maxWidth: "100%",
                paddingRight: "calc(1.5rem * .5)",
                paddingLeft: "calc(1.5rem * .5)",
                marginTop: "0",
                flex: "0 0 auto",
                flexShrink: 0,
                width: "66.6667%",
                fontFamily: '"Open Sans", sans-serif',
              }}
            >
              <div
                className="course-header-wrap"
                style={{
                  boxSizing: "border-box",
                  fontFamily: '"Open Sans", sans-serif',
                }}
              >
                <h1
                  className="title"
                  style={{
                    boxSizing: "border-box",
                    marginTop: "0px",
                    marginBottom: "0.5rem",
                    fontSize: "35px",
                    lineHeight: "40px",
                    fontWeight: 600,
                  }}
                >
                  Curso de Formação de Praças da PM PA
                </h1>
                <p
                  className="subtitle"
                  style={{
                    boxSizing: "border-box",
                    margin: "0px 0px 10.5px",
                    marginTop: "0px",
                    fontSize: "15px",
                    marginBottom: "20px",
                  }}
                >
                  Bla Bla Bla
                </p>
                <div
                  className="rating-row"
                  style={{
                    boxSizing: "border-box",
                    fontSize: "13px",
                    fontFamily: '"Open Sans", sans-serif',
                  }}
                >
                  <span
                    className="course-badge best-seller"
                    style={{
                      boxSizing: "border-box",
                      borderRadius: "3px",
                      padding: "1px 8px",
                      color: "rgb(41, 48, 59)",
                      boxShadow:
                        "rgba(20, 23, 28, 0.1) 0px 0px 1px 1px, rgba(20, 23, 28, 0.1) 0px 3px 1px 0px",
                      pointerEvents: "none",
                      fontWeight: 700,
                      position: "relative",
                      lineHeight: 1.5,
                      textAlign: "center",
                      textTransform: "uppercase",
                      display: "inline-block",
                      marginRight: "15px",
                      marginBottom: "7px",
                      background: "rgb(244, 193, 80)",
                      fontFamily: '"Open Sans", sans-serif',
                      fontSize: "9px",
                    }}
                  >
                    Iniciante
                  </span>
                  <i
                    className="fas fa-star"
                    style={{
                      boxSizing: "border-box",
                      fontVariant: "normal",
                      WebkitFontSmoothing: "antialiased",
                      display: "inline-block",
                      fontStyle: "normal",
                      textRendering: "auto",
                      lineHeight: 1,
                      fontFamily: '"Font Awesome 5 Free"',
                      fontWeight: 900,
                    }}
                  />
                  <i
                    className="fas fa-star"
                    style={{
                      boxSizing: "border-box",
                      fontVariant: "normal",
                      WebkitFontSmoothing: "antialiased",
                      display: "inline-block",
                      fontStyle: "normal",
                      textRendering: "auto",
                      lineHeight: 1,
                      fontFamily: '"Font Awesome 5 Free"',
                      fontWeight: 900,
                    }}
                  />
                  <i
                    className="fas fa-star"
                    style={{
                      boxSizing: "border-box",
                      fontVariant: "normal",
                      WebkitFontSmoothing: "antialiased",
                      display: "inline-block",
                      fontStyle: "normal",
                      textRendering: "auto",
                      lineHeight: 1,
                      fontFamily: '"Font Awesome 5 Free"',
                      fontWeight: 900,
                    }}
                  />
                  <i
                    className="fas fa-star"
                    style={{
                      boxSizing: "border-box",
                      fontVariant: "normal",
                      WebkitFontSmoothing: "antialiased",
                      display: "inline-block",
                      fontStyle: "normal",
                      textRendering: "auto",
                      lineHeight: 1,
                      fontFamily: '"Font Awesome 5 Free"',
                      fontWeight: 900,
                    }}
                  />
                  <i
                    className="fas fa-star"
                    style={{
                      boxSizing: "border-box",
                      fontVariant: "normal",
                      WebkitFontSmoothing: "antialiased",
                      display: "inline-block",
                      fontStyle: "normal",
                      textRendering: "auto",
                      lineHeight: 1,
                      fontFamily: '"Font Awesome 5 Free"',
                      fontWeight: 900,
                    }}
                  />
                  <span
                    className="d-inline-block average-rating"
                    style={{
                      boxSizing: "border-box",
                      marginRight: "15px",
                      marginBottom: "7px",
                      fontSize: "13px",
                      fontFamily: '"Open Sans", sans-serif',
                      display: "inline-block",
                    }}
                  >
                    0
                  </span>
                  <span
                    style={{
                      boxSizing: "border-box",
                      marginRight: "15px",
                      marginBottom: "7px",
                      fontSize: "13px",
                      fontFamily: '"Open Sans", sans-serif',
                    }}
                  >
                    (0 Avaliações)
                  </span>
                  <span
                    className="enrolled-num"
                    style={{
                      boxSizing: "border-box",
                      marginRight: "15px",
                      marginBottom: "7px",
                      fontSize: "13px",
                      fontFamily: '"Open Sans", sans-serif',
                    }}
                  >
                    {"464 Alunos matriculados"}
                  </span>
                  <span
                    className="comment"
                    style={{
                      boxSizing: "border-box",
                      marginRight: "15px",
                      marginBottom: "7px",
                      fontSize: "13px",
                      fontFamily: '"Open Sans", sans-serif',
                    }}
                  >
                    <i
                      className="fas fa-comment"
                      style={{
                        boxSizing: "border-box",
                        fontVariant: "normal",
                        WebkitFontSmoothing: "antialiased",
                        display: "inline-block",
                        fontStyle: "normal",
                        textRendering: "auto",
                        lineHeight: 1,
                        fontFamily: '"Font Awesome 5 Free"',
                        fontWeight: 900,
                        marginRight: "7px",
                        fontSize: "13px",
                      }}
                    />
                    English
                  </span>
                </div>
                <div
                  className="created-row"
                  style={{
                    boxSizing: "border-box",
                    fontFamily: '"Open Sans", sans-serif',
                  }}
                >
                  <span
                    className="created-by"
                    style={{
                      boxSizing: "border-box",
                      marginRight: "15px",
                      marginBottom: "7px",
                      fontSize: "13px",
                      fontFamily: '"Open Sans", sans-serif',
                    }}
                  >
                    {"Criado por"}
                    <a
                      className="text-14px fw-600 text-decoration-none"
                      href="https://v3.educaflix.co/home/instructor_page/2"
                      style={{
                        boxSizing: "border-box",
                        transition: "all 0.3s ease 0s",
                        color: "rgb(255, 255, 255)",
                        fontFamily: '"Open Sans", sans-serif',
                        textDecoration: "none",
                        fontSize: "14px",
                        fontWeight: 600,
                      }}
                    >
                      FASPM PA
                    </a>
                  </span>
                  <br style={{ boxSizing: "border-box" }} />
                  <span
                    className="last-updated-date d-inline-block mt-2"
                    style={{
                      boxSizing: "border-box",
                      marginRight: "15px",
                      marginBottom: "7px",
                      fontSize: "13px",
                      fontFamily: '"Open Sans", sans-serif',
                      display: "inline-block",
                      marginTop: "0.5rem",
                    }}
                  >
                    Última atualização Sat, 21-Oct-2023
                  </span>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4"
              style={{
                boxSizing: "border-box",
                maxWidth: "100%",
                paddingRight: "calc(1.5rem * .5)",
                paddingLeft: "calc(1.5rem * .5)",
                marginTop: "0",
                flex: "0 0 auto",
                flexShrink: 0,
                width: "33.3333%",
                fontFamily: '"Open Sans", sans-serif',
              }}
            />
          </div>
        </div>
      </section>
      <Body />
      <style
        dangerouslySetInnerHTML={{
          __html: `
html {
  box-sizing: border-box;
  color: rgb(34, 34, 34);
  font-size: 1em;
  line-height: 1.4;
}

body {
  box-sizing: border-box;
  margin: 0px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: rgb(33, 37, 41);
  text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
  background: rgb(255, 255, 255);
  background-color: rgb(255, 255, 255);
  font-family: "Open Sans", sans-serif;
}
`,
        }}
      />
    </>
  );
}
