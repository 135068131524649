import React, { useState, useEffect } from "react";
import { useAuth } from "../providers/auth";
import axios from "../axiosfetch";

const useDetailPage = (movieId, apiKey) => {
  const { user } = useAuth();
  const [singleMovie, setSingleMovie] = useState([""]);
  const [courseDetails, setcourseDetails] = useState([""]);
  const [recommended, setRecommended] = useState([""]);
  const [apostilas, setApostilas] = useState([""]);
  const [quiz, setQuiz] = useState([""]);
  const [videos, setVideos] = useState([""]);
  const [disciplina, setDisciplina] = useState([""]);
  const [cast, setCast] = useState([""]);

  useEffect(() => {
    window.scrollTo(0, 0);
    axios
      .get(`/sectionsid?section_id=${movieId}&auth_token=${user.token}`)
      .then((response) => {
        const { data } = response;
        const videos = data[0].lessons.filter(item => item.lesson_type === 'video')
        const arquivos = data[0].lessons.filter(item => item.lesson_type === 'other')
        const atividades = data[0].lessons.filter(item => item.lesson_type === 'quiz')
        setcourseDetails(data[0]);
        setSingleMovie(data[0]);
        setDisciplina(data[0].title);
        setApostilas(arquivos);
        setQuiz(atividades);
        setRecommended(videos);
      });
  }, [movieId]);
  return { singleMovie, recommended, apostilas, videos, cast, disciplina, courseDetails, quiz };
};

export default useDetailPage;
