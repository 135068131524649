import React from "react";
import MovieCard from "../MovieCardSearch";
import { apiKey } from "../../utils";
import useSearch from "../../hooks/useSearch";
import { useAuth } from "../../providers/auth";
import "./SearchPage.css";

const SearchPage = () => {
  const { user } = useAuth();
  const { query, searchResults, setQuery } = useSearch(user.token);
  // console.log('searchResults', searchResults);
  return (
    <main className="searchPage movieRows__container">
      <section>
        <input
          className="searchPage__searchBar"
          onChange={(e) => setQuery(e.target.value)}
          type="text"
          placeholder="Título, aula ou conteúdo"
        />
        <div className="searchPage__gallery">
          <div className="searchPage__container">
            {query
              ? searchResults.map((result) => (
                  <MovieCard
                    id={result.id}
                    key={result.id}
                    poster={result.card_img}
                    title={result.title}
                    watching={false}
                    urlvideo={result.video_url_web}
                    course_id={result.course_id}
                    // disciplina={result.course.title}
                  />
                ))
              : null}
          </div>
        </div>
      </section>
    </main>
  );
};

export default SearchPage;
