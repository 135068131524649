import React from "react";
import { useAuth } from "../../providers/auth";
import { Redirect } from "react-router-dom";
import ImageSlider from "../ImageSlider";
import Collections from "../Collections";
import MoviesRows from "../MoviesRows";
import "./Home.css";

const Home = () => {
  const { user } = useAuth();
  const isAuthenticated = !!user.token;

  if (!isAuthenticated) {
    return <Redirect to="/login" />;
  }

  return (
    <main className="home">
      <div className="home__container movieRows__container">
        <ImageSlider />
        <Collections />
        <MoviesRows />
      </div>
    </main>
  );
};

export default Home;
