import React, { useState, useEffect, useRef } from "react";
import PDFViewer from "mgr-pdf-viewer-react";
import { Button } from "@material-ui/core";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import ZoomInIcon from "@material-ui/icons/ZoomIn";
import ZoomOutIcon from "@material-ui/icons/ZoomOut";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { useHistory, useLocation } from "react-router-dom";
import { useAuth } from "../../providers/auth";
import axios from "../../axiosfetch";
import "./AtividadePage.css";

const api_url = process.env.REACT_APP_API_URL;

const PdfPage = () => {
  const location = useLocation();
  const history = useHistory();
  const { user } = useAuth();
  const documentRef = useRef(null);
  const [webViewContent, setWebViewContent] = useState("");
  const id = location.pathname.split("/").pop();
  const urlPDF = location.state.urlPDF;
  const title = location.state.title;
  const disciplina = location.state.disciplina;

  console.log(user.token);

  const handleGoBack = () => {
    history.goBack();
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setWebViewContent(
          `${api_url}/quiz_mobile_web_view?lesson_id=${id}&auth_token=${user.token}`
        );
        // `${api_url}/quiz_mobile_web_view_homolog?lesson_id=${id}&auth_token=${user.token}`
        // setWebViewContent(`https://v3.educaflix.co/home/quiz_mobile_web_view/${id}`);
        // const response = await axios.get(`/quiz_mobile_web_view?lesson_id=${id}&auth_token=${user.token}`);
        // // setWebViewContent(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="pdf-reader-container">
      <div className="pdf-viewer" ref={documentRef}>
        <Button
          className="detailPage__trailerBtn back-button"
          startIcon={<ArrowBackIosIcon />}
          onClick={handleGoBack}
        >
          Voltar
        </Button>
        <br />
        <br />
        <h1 className="discicplina text-white">{disciplina}</h1>
        <h4 className="conteudo text-white">{title}</h4>
        {/* <div dangerouslySetInnerHTML={{ __html: webViewContent }} /> */}
        <iframe
          src={webViewContent}
          title="Atividades"
          style={{
            border: "none",
            minWidth: "1080px",
            height: "150vh",

            // width: "800px",
            // height: "500px" // Defina a altura conforme necessário
          }}
          onLoad={(e) => {
            e.target.contentWindow.enviarAlturaELargura();
          }}
          onMessage={(e) => {
            if (e.data.altura && e.data.largura) {
              e.target.style.height = `${e.data.altura}px`;
              e.target.style.width = `${e.data.largura}px`;
            }
          }}
        />
      </div>
    </div>
  );
};

export default PdfPage;
