import React, { useState, useEffect } from "react";
import { useAuth } from "../providers/auth";
import axios from "../axiosfetch";

const useSearch = (apiKey) => {
  const { user } = useAuth();
  const [query, setQuery] = useState("");
  const [searchResults, setSearchResults] = useState([""]);
  useEffect(() => {
    if (query != "") {
      axios
        .get(
          `search_lesson?titulo=${query}&auth_token=${apiKey ? apiKey : 'YPKCRI2341'}`
        )
        .then((response) => {
          const { data } = response;
          setSearchResults(data);
        });
    }
  }, [query]);
  return { query, searchResults, setQuery };
};
export default useSearch;
