import React, { createContext, useContext, useState } from "react";

export const AppContext = createContext({});

export const AuthProvider = (props) => {
  const [user, setUser] = useState({
      "user_id": "",
      "first_name": "",
      "last_name": "",
      "email": "",
      "cep": "",
      "endereco": "",
      "telefone": "",
      "image":"",
      "role": "",
      "validity": 1,
      "token": "",
      "auth_token": "",
      "datanascimento":"",
      "sexo":""
  });
  
  return (
    <AppContext.Provider value={{ user, setUser }}>
      {props.children}
    </AppContext.Provider>
  );
};

export const useAuth = () => useContext(AppContext);