import React, { useState, useEffect } from "react";
import { useAuth } from "../../providers/auth";
import axios from "../../axiosfetch";
import Swal from "sweetalert2";
import { withRouter, useHistory } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faComment, faPlay } from '@fortawesome/free-solid-svg-icons';
import "./CourseDetailPage.css";


const CourseDetailPage = (props) => {
  const history = useHistory();

  function viewMore(element, action) {
    const descriptionContent = element.parentElement.querySelector('.description-content-wrap');
    
    if (action === 'hide') {
      descriptionContent.style.maxHeight = '200px'; // Defina a altura máxima desejada
      element.innerText = '- Ver menos';
      element.setAttribute('onclick', 'viewMore(this, \'show\')');
    } else {
      descriptionContent.style.maxHeight = null;
      element.innerText = '+ Ver mais';
      element.setAttribute('onclick', 'viewMore(this, \'hide\')');
    }
  }

  return (
    <div>
      <section className="course-header-area">
        {/* ... (conteúdo da seção) */}
        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptate commodi aliquid atque praesentium velit nisi odit, laudantium laboriosam est, asperiores recusandae alias qui mollitia repudiandae error fugiat eos nihil vitae?</p>
      </section>

      <section className="course-content-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 order-last order-lg-first radius-10 mt-4 bg-white p-30-40 box-shadow-5">
              {/* ... (conteúdo da seção) */}
              <div className="description-box view-more-parent">
                <div className="view-more" onClick={() => viewMore(this, 'hide')}>+ Ver mais</div>
                {/* ... (conteúdo da box de descrição) */}
              </div>
              <div className="course-curriculum-box">
                {/* ... (conteúdo da box de currículo) */}
              </div>
              <div className="compare-box view-more-parent">
                {/* ... (conteúdo da box de comparação) */}
              </div>
              <div className="about-instructor-box">
                {/* ... (conteúdo da box de sobre o instrutor) */}
              </div>
              <div className="student-feedback-box mt-5 pb-3">
                {/* ... (conteúdo da box de feedback do aluno) */}
              </div>
            </div>
            <div className="col-lg-4 order-first order-lg-last">
              <div className="course-sidebar box-shadow-5 natural" style={{ top: 'auto' }}>
                {/* ... (conteúdo da sidebar) */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default CourseDetailPage;
