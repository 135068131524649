import React from "react";

export default function Body() {
  return (
    <>
      <div
        className="container"
        style={{
          boxSizing: "border-box",
          width: "100%",
          paddingRight: "var(--bs-gutter-x,.75rem)",
          paddingLeft: "var(--bs-gutter-x,.75rem)",
          marginRight: "auto",
          marginLeft: "auto",
          maxWidth: "1320px",
          fontFamily: '"Open Sans", sans-serif',
        }}
      >
        <div
          className="row"
          style={{
            boxSizing: "border-box",
            display: "flex",
            flexWrap: "wrap",
            marginTop: "calc(0 * -1)",
            marginRight: "calc(1.5rem * -.5)",
            marginLeft: "calc(1.5rem * -.5)",
            fontFamily: '"Open Sans", sans-serif',
          }}
        >
          <div
            className="col-lg-8 order-last order-lg-first radius-10 mt-4 bg-white p-30-40 box-shadow-5"
            style={{
              boxSizing: "border-box",
              maxWidth: "100%",
              flex: "0 0 auto",
              flexShrink: 0,
              width: "66.6667%",
              padding: "30px 40px",
              paddingRight: "40px",
              paddingLeft: "40px",
              fontFamily: '"Open Sans", sans-serif',
              marginTop: "1.5rem",
              backgroundColor: "rgb(255, 255, 255)",
              order: -1,
              borderRadius: "10px",
              boxShadow: "rgba(0, 0, 0, 0.11) 0px 1px 5px",
            }}
          >
            <div
              className="description-box view-more-parent"
              style={{
                boxSizing: "border-box",
                overflow: "hidden",
                position: "relative",
                maxHeight: "260px",
                marginTop: "10px",
                marginBottom: "40px",
                fontFamily: '"Open Sans", sans-serif',
              }}
            >
              <div
                className="view-more"
                style={{
                  boxSizing: "border-box",
                  background:
                    "linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.95), rgb(255, 255, 255))",
                  padding: "30px 3px 3px",
                  position: "absolute",
                  bottom: "0px",
                  left: "0px",
                  width: "100%",
                  display: "block",
                  color: "rgb(0, 119, 145)",
                  cursor: "pointer",
                  zIndex: 5,
                  fontFamily: '"Open Sans", sans-serif',
                }}
              >
                + Ver mais
              </div>
              <div
                className="description-title"
                style={{
                  boxSizing: "border-box",
                  margin: "0px 0px 20px",
                  fontSize: "22px",
                  fontWeight: 600,
                  fontFamily: '"Open Sans", sans-serif',
                }}
              >
                Visão geral do curso
              </div>
              <div
                className="description-content-wrap"
                style={{
                  boxSizing: "border-box",
                  fontFamily: '"Open Sans", sans-serif',
                }}
              >
                <div
                  className="description-content"
                  style={{
                    boxSizing: "border-box",
                    fontFamily: '"Open Sans", sans-serif',
                  }}
                />
              </div>
            </div>
            <div
              className="course-curriculum-box"
              style={{
                boxSizing: "border-box",
                marginBottom: "40px",
                fontFamily: '"Open Sans", sans-serif',
              }}
            >
              <div
                className="course-curriculum-title clearfix mt-5 mb-3"
                style={{
                  boxSizing: "border-box",
                  paddingRight: "31px",
                  fontFamily: '"Open Sans", sans-serif',
                  marginTop: "3rem",
                  marginBottom: "1rem",
                }}
              >
                <div
                  className="title float-start"
                  style={{
                    boxSizing: "border-box",
                    margin: "0px 0px 10px",
                    fontSize: "22px",
                    fontWeight: 600,
                    fontFamily: '"Open Sans", sans-serif',
                    cssFloat: "left",
                  }}
                >
                  Currículo para este curso
                </div>
                <div
                  className="float-end mt-2"
                  style={{
                    boxSizing: "border-box",
                    fontFamily: '"Open Sans", sans-serif',
                    cssFloat: "right",
                    marginTop: "0.5rem",
                  }}
                >
                  <span
                    className="total-lectures"
                    style={{
                      boxSizing: "border-box",
                      fontFamily: '"Open Sans", sans-serif',
                    }}
                  >
                    {"3 aulas"}
                  </span>
                  <span
                    className="total-time"
                    style={{
                      boxSizing: "border-box",
                      width: "130px",
                      display: "inline-block",
                      textAlign: "right",
                      fontFamily: '"Open Sans", sans-serif',
                    }}
                  >
                    {"00:46:09 Horas"}
                  </span>
                </div>
              </div>
              <div
                className="course-curriculum-accordion"
                style={{
                  boxSizing: "border-box",
                  border: "1px solid rgb(222, 223, 224)",
                  borderRadius: "10px",
                  fontFamily: '"Open Sans", sans-serif',
                }}
              >
                <div
                  className="lecture-group-wrapper"
                  style={{
                    boxSizing: "border-box",
                    fontFamily: '"Open Sans", sans-serif',
                  }}
                >
                  <div
                    className="lecture-group-title clearfix"
                    aria-expanded="true"
                    style={{
                      boxSizing: "border-box",
                      padding: "10px 30px 10px 45px",
                      background: "rgb(255, 255, 255)",
                      borderBottom: "1px solid rgb(225, 225, 225)",
                      position: "relative",
                      cursor: "pointer",
                      height: "auto",
                      color: "rgb(80, 87, 99)",
                      borderRadius: "10px 10px 0px 0px",
                      fontFamily: '"Open Sans", sans-serif',
                    }}
                  >
                    <div
                      className="title float-start"
                      style={{
                        boxSizing: "border-box",
                        maxWidth: "60%",
                        fontWeight: 600,
                        fontFamily: '"Open Sans", sans-serif',
                        cssFloat: "left",
                      }}
                    >
                      {"Atualidades"}
                    </div>
                    <div
                      className="float-end"
                      style={{
                        boxSizing: "border-box",
                        fontFamily: '"Open Sans", sans-serif',
                        cssFloat: "right",
                      }}
                    >
                      <span
                        className="total-lectures"
                        style={{
                          boxSizing: "border-box",
                          display: "none",
                          fontFamily: '"Open Sans", sans-serif',
                        }}
                      >
                        {"0 aulas"}
                      </span>
                      <span
                        className="total-time"
                        style={{
                          boxSizing: "border-box",
                          width: "130px",
                          display: "inline-block",
                          textAlign: "right",
                          fontFamily: '"Open Sans", sans-serif',
                        }}
                      >
                        {"00:00:00 Horas"}
                      </span>
                    </div>
                  </div>
                  <div
                    id="collapse5"
                    className="lecture-list collapse show"
                    style={{
                      boxSizing: "border-box",
                      fontFamily: '"Open Sans", sans-serif',
                    }}
                  >
                    <ul
                      style={{
                        boxSizing: "border-box",
                        margin: "0px",
                        padding: "0px",
                        listStyle: "none",
                        marginTop: "0px",
                        marginBottom: "0px",
                        paddingLeft: "0px",
                        fontFamily: '"Open Sans", sans-serif',
                      }}
                    />
                  </div>
                </div>
                <div
                  className="lecture-group-wrapper"
                  style={{
                    boxSizing: "border-box",
                    fontFamily: '"Open Sans", sans-serif',
                  }}
                >
                  <div
                    className="lecture-group-title clearfix"
                    style={{
                      boxSizing: "border-box",
                      padding: "10px 30px 10px 45px",
                      background: "rgb(255, 255, 255)",
                      borderBottom: "1px solid rgb(225, 225, 225)",
                      position: "relative",
                      cursor: "pointer",
                      height: "auto",
                      color: "rgb(80, 87, 99)",
                      fontFamily: '"Open Sans", sans-serif',
                    }}
                  >
                    <div
                      className="title float-start"
                      style={{
                        boxSizing: "border-box",
                        maxWidth: "60%",
                        fontWeight: 600,
                        fontFamily: '"Open Sans", sans-serif',
                        cssFloat: "left",
                      }}
                    >
                      {"Estatuto da PM"}
                    </div>
                    <div
                      className="float-end"
                      style={{
                        boxSizing: "border-box",
                        fontFamily: '"Open Sans", sans-serif',
                        cssFloat: "right",
                      }}
                    >
                      <span
                        className="total-lectures"
                        style={{
                          boxSizing: "border-box",
                          fontFamily: '"Open Sans", sans-serif',
                        }}
                      >
                        {"0 aulas"}
                      </span>
                      <span
                        className="total-time"
                        style={{
                          boxSizing: "border-box",
                          width: "130px",
                          display: "inline-block",
                          textAlign: "right",
                          fontFamily: '"Open Sans", sans-serif',
                        }}
                      >
                        {"00:00:00 Horas"}
                      </span>
                    </div>
                  </div>
                  <div
                    id="collapse6"
                    className="lecture-list collapse"
                    style={{
                      boxSizing: "border-box",
                      display: "none",
                      fontFamily: '"Open Sans", sans-serif',
                    }}
                  >
                    <ul
                      style={{
                        boxSizing: "border-box",
                        margin: "0px",
                        padding: "0px",
                        listStyle: "none",
                        marginTop: "0px",
                        marginBottom: "0px",
                        paddingLeft: "0px",
                        fontFamily: '"Open Sans", sans-serif',
                      }}
                    />
                  </div>
                </div>
                <div
                  className="lecture-group-wrapper"
                  style={{
                    boxSizing: "border-box",
                    fontFamily: '"Open Sans", sans-serif',
                  }}
                >
                  <div
                    className="lecture-group-title clearfix"
                    style={{
                      boxSizing: "border-box",
                      padding: "10px 30px 10px 45px",
                      background: "rgb(255, 255, 255)",
                      borderBottom: "1px solid rgb(225, 225, 225)",
                      position: "relative",
                      cursor: "pointer",
                      height: "auto",
                      color: "rgb(80, 87, 99)",
                      fontFamily: '"Open Sans", sans-serif',
                    }}
                  >
                    <div
                      className="title float-start"
                      style={{
                        boxSizing: "border-box",
                        maxWidth: "60%",
                        fontWeight: 600,
                        fontFamily: '"Open Sans", sans-serif',
                        cssFloat: "left",
                      }}
                    >
                      {"Direitos Humanos"}
                    </div>
                    <div
                      className="float-end"
                      style={{
                        boxSizing: "border-box",
                        fontFamily: '"Open Sans", sans-serif',
                        cssFloat: "right",
                      }}
                    >
                      <span
                        className="total-lectures"
                        style={{
                          boxSizing: "border-box",
                          fontFamily: '"Open Sans", sans-serif',
                        }}
                      >
                        {"3 aulas"}
                      </span>
                      <span
                        className="total-time"
                        style={{
                          boxSizing: "border-box",
                          width: "130px",
                          display: "inline-block",
                          textAlign: "right",
                          fontFamily: '"Open Sans", sans-serif',
                        }}
                      >
                        {"00:46:09 Horas"}
                      </span>
                    </div>
                  </div>
                  <div
                    id="collapse9"
                    className="lecture-list collapse"
                    style={{
                      boxSizing: "border-box",
                      display: "none",
                      fontFamily: '"Open Sans", sans-serif',
                    }}
                  >
                    <ul
                      style={{
                        boxSizing: "border-box",
                        margin: "0px",
                        padding: "0px",
                        listStyle: "none",
                        marginTop: "0px",
                        marginBottom: "0px",
                        paddingLeft: "0px",
                        fontFamily: '"Open Sans", sans-serif',
                      }}
                    >
                      <li
                        className="lecture has-preview text-14px"
                        style={{
                          boxSizing: "border-box",
                          padding: "12px 30px 12px 55px",
                          borderBottom: "1px solid rgb(232, 233, 235)",
                          borderLeft: "1px solid rgb(232, 233, 235)",
                          borderRight: "1px solid rgb(232, 233, 235)",
                          position: "relative",
                          color: "rgb(0, 119, 145)",
                          fontFamily: '"Open Sans", sans-serif',
                          fontSize: "14px",
                        }}
                      >
                        <span
                          className="lecture-title"
                          style={{
                            boxSizing: "border-box",
                            transition: "all 0.3s ease 0s",
                            display: "inline-block",
                            cursor: "pointer",
                            fontFamily: '"Open Sans", sans-serif',
                          }}
                        >
                          Noções de Direitos Humanos Parte I
                        </span>
                        <div
                          className="lecture-info float-lg-end"
                          style={{
                            boxSizing: "border-box",
                            fontFamily: '"Open Sans", sans-serif',
                            cssFloat: "right",
                          }}
                        >
                          <span
                            className="lecture-time ps-2"
                            style={{
                              boxSizing: "border-box",
                              fontFamily: '"Open Sans", sans-serif',
                              paddingLeft: "0.5rem",
                            }}
                          >
                            {"00:23:04"}
                          </span>
                        </div>
                      </li>
                      <li
                        className="lecture has-preview text-14px"
                        style={{
                          boxSizing: "border-box",
                          padding: "12px 30px 12px 55px",
                          borderBottom: "1px solid rgb(232, 233, 235)",
                          borderLeft: "1px solid rgb(232, 233, 235)",
                          borderRight: "1px solid rgb(232, 233, 235)",
                          position: "relative",
                          color: "rgb(0, 119, 145)",
                          fontFamily: '"Open Sans", sans-serif',
                          fontSize: "14px",
                        }}
                      >
                        <span
                          className="lecture-title"
                          style={{
                            boxSizing: "border-box",
                            transition: "all 0.3s ease 0s",
                            display: "inline-block",
                            cursor: "pointer",
                            fontFamily: '"Open Sans", sans-serif',
                          }}
                        >
                          Noções de Direitos Humanos Parte I
                        </span>
                        <div
                          className="lecture-info float-lg-end"
                          style={{
                            boxSizing: "border-box",
                            fontFamily: '"Open Sans", sans-serif',
                            cssFloat: "right",
                          }}
                        >
                          <span
                            className="lecture-time ps-2"
                            style={{
                              boxSizing: "border-box",
                              fontFamily: '"Open Sans", sans-serif',
                              paddingLeft: "0.5rem",
                            }}
                          >
                            <span
                              className="opacity-0"
                              style={{
                                boxSizing: "border-box",
                                opacity: 0,
                                fontFamily: '"Open Sans", sans-serif',
                              }}
                            >
                              .
                            </span>{" "}
                          </span>
                        </div>
                      </li>
                      <li
                        className="lecture has-preview text-14px"
                        style={{
                          boxSizing: "border-box",
                          padding: "12px 30px 12px 55px",
                          borderBottom: "1px solid rgb(232, 233, 235)",
                          borderLeft: "1px solid rgb(232, 233, 235)",
                          borderRight: "1px solid rgb(232, 233, 235)",
                          position: "relative",
                          color: "rgb(0, 119, 145)",
                          fontFamily: '"Open Sans", sans-serif',
                          fontSize: "14px",
                        }}
                      >
                        <span
                          className="lecture-title"
                          style={{
                            boxSizing: "border-box",
                            transition: "all 0.3s ease 0s",
                            display: "inline-block",
                            cursor: "pointer",
                            fontFamily: '"Open Sans", sans-serif',
                          }}
                        >
                          Noções de Direitos Humanos Parte I Y
                        </span>
                        <div
                          className="lecture-info float-lg-end"
                          style={{
                            boxSizing: "border-box",
                            fontFamily: '"Open Sans", sans-serif',
                            cssFloat: "right",
                          }}
                        >
                          <span
                            className="lecture-time ps-2"
                            style={{
                              boxSizing: "border-box",
                              fontFamily: '"Open Sans", sans-serif',
                              paddingLeft: "0.5rem",
                            }}
                          >
                            {"00:23:05"}
                          </span>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div
                  className="lecture-group-wrapper"
                  style={{
                    boxSizing: "border-box",
                    fontFamily: '"Open Sans", sans-serif',
                  }}
                >
                  <div
                    className="lecture-group-title clearfix"
                    style={{
                      boxSizing: "border-box",
                      padding: "10px 30px 10px 45px",
                      background: "rgb(255, 255, 255)",
                      borderBottom: "1px solid rgb(225, 225, 225)",
                      position: "relative",
                      cursor: "pointer",
                      height: "auto",
                      color: "rgb(80, 87, 99)",
                      fontFamily: '"Open Sans", sans-serif',
                    }}
                  >
                    <div
                      className="title float-start"
                      style={{
                        boxSizing: "border-box",
                        maxWidth: "60%",
                        fontWeight: 600,
                        fontFamily: '"Open Sans", sans-serif',
                        cssFloat: "left",
                      }}
                    >
                      {"Direito Constitucional"}
                    </div>
                    <div
                      className="float-end"
                      style={{
                        boxSizing: "border-box",
                        fontFamily: '"Open Sans", sans-serif',
                        cssFloat: "right",
                      }}
                    >
                      <span
                        className="total-lectures"
                        style={{
                          boxSizing: "border-box",
                          fontFamily: '"Open Sans", sans-serif',
                        }}
                      >
                        {"0 aulas"}
                      </span>
                      <span
                        className="total-time"
                        style={{
                          boxSizing: "border-box",
                          width: "130px",
                          display: "inline-block",
                          textAlign: "right",
                          fontFamily: '"Open Sans", sans-serif',
                        }}
                      >
                        {"00:00:00 Horas"}
                      </span>
                    </div>
                  </div>
                  <div
                    id="collapse8"
                    className="lecture-list collapse"
                    style={{
                      boxSizing: "border-box",
                      display: "none",
                      fontFamily: '"Open Sans", sans-serif',
                    }}
                  >
                    <ul
                      style={{
                        boxSizing: "border-box",
                        margin: "0px",
                        padding: "0px",
                        listStyle: "none",
                        marginTop: "0px",
                        marginBottom: "0px",
                        paddingLeft: "0px",
                        fontFamily: '"Open Sans", sans-serif',
                      }}
                    />
                  </div>
                </div>
                <div
                  className="lecture-group-wrapper"
                  style={{
                    boxSizing: "border-box",
                    fontFamily: '"Open Sans", sans-serif',
                  }}
                >
                  <div
                    className="lecture-group-title clearfix"
                    style={{
                      boxSizing: "border-box",
                      padding: "10px 30px 10px 45px",
                      background: "rgb(255, 255, 255)",
                      borderBottom: "1px solid rgb(225, 225, 225)",
                      position: "relative",
                      cursor: "pointer",
                      height: "auto",
                      color: "rgb(80, 87, 99)",
                      borderRadius: "0px 0px 10px 10px",
                      fontFamily: '"Open Sans", sans-serif',
                    }}
                  >
                    <div
                      className="title float-start"
                      style={{
                        boxSizing: "border-box",
                        maxWidth: "60%",
                        fontWeight: 600,
                        fontFamily: '"Open Sans", sans-serif',
                        cssFloat: "left",
                      }}
                    >
                      {"Língua Portuguesa"}
                    </div>
                    <div
                      className="float-end"
                      style={{
                        boxSizing: "border-box",
                        fontFamily: '"Open Sans", sans-serif',
                        cssFloat: "right",
                      }}
                    >
                      <span
                        className="total-lectures"
                        style={{
                          boxSizing: "border-box",
                          fontFamily: '"Open Sans", sans-serif',
                        }}
                      >
                        {"0 aulas"}
                      </span>
                      <span
                        className="total-time"
                        style={{
                          boxSizing: "border-box",
                          width: "130px",
                          display: "inline-block",
                          textAlign: "right",
                          fontFamily: '"Open Sans", sans-serif',
                        }}
                      >
                        {"00:00:00 Horas"}
                      </span>
                    </div>
                  </div>
                  <div
                    id="collapse7"
                    className="lecture-list collapse"
                    style={{
                      boxSizing: "border-box",
                      display: "none",
                      fontFamily: '"Open Sans", sans-serif',
                    }}
                  >
                    <ul
                      style={{
                        boxSizing: "border-box",
                        margin: "0px",
                        padding: "0px",
                        listStyle: "none",
                        marginTop: "0px",
                        marginBottom: "0px",
                        paddingLeft: "0px",
                        fontFamily: '"Open Sans", sans-serif',
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              className="compare-box view-more-parent"
              style={{
                boxSizing: "border-box",
                overflow: "hidden",
                position: "relative",
                maxHeight: "580px",
                marginBottom: "40px",
                fontFamily: '"Open Sans", sans-serif',
              }}
            >
              <div
                className="view-more"
                style={{
                  boxSizing: "border-box",
                  background:
                    "linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.95), rgb(255, 255, 255))",
                  padding: "30px 3px 3px",
                  position: "absolute",
                  bottom: "0px",
                  left: "0px",
                  width: "100%",
                  display: "block",
                  color: "rgb(0, 119, 145)",
                  cursor: "pointer",
                  zIndex: 5,
                  fontFamily: '"Open Sans", sans-serif',
                }}
              >
                + Ver mais
              </div>
              <div
                className="compare-title"
                style={{
                  boxSizing: "border-box",
                  margin: "0px 0px 15px",
                  fontSize: "22px",
                  fontWeight: 600,
                  fontFamily: '"Open Sans", sans-serif',
                }}
              >
                Outros cursos relacionados
              </div>
              <div
                className="compare-courses-wrap"
                style={{
                  boxSizing: "border-box",
                  fontFamily: '"Open Sans", sans-serif',
                }}
              >
                <div
                  className="course-comparism-item-container this-course"
                  style={{
                    boxSizing: "border-box",
                    borderTop: "1px solid rgb(222, 223, 224)",
                    borderBottom: "2px solid rgb(222, 223, 224)",
                    fontFamily: '"Open Sans", sans-serif',
                  }}
                >
                  <div
                    className="course-comparism-item clearfix"
                    style={{
                      boxSizing: "border-box",
                      padding: "15px",
                      color: "rgb(41, 48, 59)",
                      fontFamily: '"Open Sans", sans-serif',
                    }}
                  >
                    <div
                      className="item-image float-start mt-4 mt-md-0"
                      style={{
                        boxSizing: "border-box",
                        height: "auto",
                        width: "18%",
                        position: "relative",
                        fontFamily: '"Open Sans", sans-serif',
                        cssFloat: "left",
                        marginTop: "0px",
                      }}
                    >
                      <a
                        href="https://v3.educaflix.co/home/course/curso-de-forma%C3%A7%C3%A3o-de-pra%C3%A7as-da-pm-pa/3"
                        style={{
                          boxSizing: "border-box",
                          color: "rgb(18, 34, 34)",
                          transition: "all 0.1s ease 0s",
                          textDecoration: "none",
                          display: "block",
                          fontFamily: '"Open Sans", sans-serif',
                        }}
                      >
                        <img
                          className="img-fluid"
                          src="https://v3.educaflix.co/home/course/curso-de-formação-de-praças-da-pm-pa/2"
                          style={{
                            boxSizing: "border-box",
                            verticalAlign: "middle",
                            maxWidth: "100%",
                            height: "auto",
                          }}
                        />
                      </a>
                      <div
                        className="item-duration"
                        style={{
                          boxSizing: "border-box",
                          position: "absolute",
                          width: "100%",
                          bottom: "0px",
                          textAlign: "center",
                          left: "0px",
                          fontFamily: '"Open Sans", sans-serif',
                        }}
                      >
                        <b
                          style={{
                            boxSizing: "border-box",
                            fontWeight: "bolder",
                          }}
                        >
                          94:30:20 Horas
                        </b>
                      </div>
                    </div>
                    <div
                      className="item-title float-start"
                      style={{
                        boxSizing: "border-box",
                        paddingLeft: "15px",
                        width: "39%",
                        fontFamily: '"Open Sans", sans-serif',
                        cssFloat: "left",
                      }}
                    >
                      <div
                        className="title"
                        style={{
                          boxSizing: "border-box",
                          fontSize: "15px",
                          fontWeight: 700,
                          wordBreak: "break-word",
                          fontFamily: '"Open Sans", sans-serif',
                        }}
                      >
                        <a
                          href="https://v3.educaflix.co/home/course/curso-de-forma%C3%A7%C3%A3o-de-pra%C3%A7as-da-pm-pa/3"
                          style={{
                            boxSizing: "border-box",
                            color: "rgb(18, 34, 34)",
                            transition: "all 0.1s ease 0s",
                            textDecoration: "none",
                            display: "block",
                            fontFamily: '"Open Sans", sans-serif',
                          }}
                        >
                          Curso de Formação de Praças da PM PA
                        </a>
                      </div>
                      <div
                        className="updated-time"
                        style={{
                          boxSizing: "border-box",
                          color: "rgb(104, 111, 122)",
                          fontSize: "13px",
                          marginTop: "3px",
                          fontFamily: '"Open Sans", sans-serif',
                        }}
                      >
                        Atualizado Sat, 21-Oct-2023
                      </div>
                    </div>
                    <div
                      className="item-details float-start"
                      style={{
                        boxSizing: "border-box",
                        position: "relative",
                        width: "43%",
                        paddingLeft: "15px",
                        fontFamily: '"Open Sans", sans-serif',
                        cssFloat: "left",
                      }}
                    >
                      <span
                        className="item-rating"
                        style={{
                          boxSizing: "border-box",
                          fontFamily: '"Open Sans", sans-serif',
                        }}
                      >
                        <i
                          className="fas fa-star"
                          style={{
                            boxSizing: "border-box",
                            fontVariant: "normal",
                            WebkitFontSmoothing: "antialiased",
                            display: "inline-block",
                            fontStyle: "normal",
                            textRendering: "auto",
                            lineHeight: 1,
                            fontFamily: '"Font Awesome 5 Free"',
                            fontWeight: 900,
                            color: "rgb(244, 193, 80)",
                            marginRight: "4px",
                            fontSize: "14px",
                          }}
                        />
                        <span
                          className="d-inline-block average-rating"
                          style={{
                            boxSizing: "border-box",
                            fontFamily: '"Open Sans", sans-serif',
                            display: "inline-block",
                          }}
                        >
                          0
                        </span>
                      </span>
                      <span
                        className="enrolled-student"
                        style={{
                          boxSizing: "border-box",
                          marginLeft: "25px",
                          fontFamily: '"Open Sans", sans-serif',
                        }}
                      >
                        <i
                          className="far fa-user"
                          style={{
                            boxSizing: "border-box",
                            fontVariant: "normal",
                            WebkitFontSmoothing: "antialiased",
                            display: "inline-block",
                            fontStyle: "normal",
                            textRendering: "auto",
                            lineHeight: 1,
                            fontWeight: 400,
                            fontFamily: '"Font Awesome 5 Free"',
                            marginRight: "2px",
                            color: "rgb(202, 203, 204)",
                            fontSize: "14px",
                          }}
                        />
                        {"464"}
                      </span>
                      <span
                        className="item-price mt-4 mt-md-0"
                        style={{
                          boxSizing: "border-box",
                          position: "absolute",
                          top: "0px",
                          right: "38px",
                          display: "flex",
                          flexDirection: "column-reverse",
                          textAlign: "right",
                          fontFamily: '"Open Sans", sans-serif',
                          marginTop: "0px",
                        }}
                      >
                        <span
                          className="original-price"
                          style={{
                            boxSizing: "border-box",
                            textDecoration: "line-through",
                            color: "rgb(104, 111, 122)",
                            fontWeight: 400,
                            fontSize: "13px",
                            fontFamily: '"Open Sans", sans-serif',
                          }}
                        >
                          R$598.8
                        </span>
                        <span
                          className="current-price"
                          style={{
                            boxSizing: "border-box",
                            color: "rgb(41, 48, 59)",
                            fontSize: "18px",
                            fontWeight: 600,
                            fontFamily: '"Open Sans", sans-serif',
                          }}
                        >
                          R$298.8
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="about-instructor-box"
              style={{
                boxSizing: "border-box",
                marginBottom: "40px",
                fontFamily: '"Open Sans", sans-serif',
              }}
            >
              <div
                className="about-instructor-title"
                style={{
                  boxSizing: "border-box",
                  margin: "0px 0px 20px",
                  display: "block",
                  fontSize: "22px",
                  fontWeight: 600,
                  fontFamily: '"Open Sans", sans-serif',
                }}
              >
                {"Sobre o instrutor"}
              </div>
              <div
                className="row justify-content-center"
                style={{
                  boxSizing: "border-box",
                  display: "flex",
                  flexWrap: "wrap",
                  marginTop: "calc(0 * -1)",
                  marginRight: "calc(1.5rem * -.5)",
                  marginLeft: "calc(1.5rem * -.5)",
                  fontFamily: '"Open Sans", sans-serif',
                  justifyContent: "center",
                }}
              >
                <div
                  className="col-md-4 top-instructor-img w-sm-100"
                  style={{
                    boxSizing: "border-box",
                    paddingRight: "calc(1.5rem * .5)",
                    paddingLeft: "calc(1.5rem * .5)",
                    marginTop: "0",
                    flex: "0 0 auto",
                    flexShrink: 0,
                    width: "33.3333%",
                    maxWidth: "250px",
                    fontFamily: '"Open Sans", sans-serif',
                  }}
                >
                  <a
                    href="https://v3.educaflix.co/home/instructor_page/1"
                    style={{
                      boxSizing: "border-box",
                      transition: "all 0.3s ease 0s",
                      color: "rgb(18, 34, 34)",
                      textDecoration: "none",
                      fontFamily: '"Open Sans", sans-serif',
                    }}
                  >
                    <img
                      className="radius-10"
                      width={226}
                      src="https://v3.educaflix.co/uploads/user_image/optimized/59c06fd7cd59f00020cc09517162589a.jpg"
                      style={{
                        boxSizing: "border-box",
                        verticalAlign: "middle",
                        borderRadius: "10px",
                      }}
                    />
                  </a>
                </div>
                <div
                  className="col-md-8 py-0 px-3 text-center text-md-start"
                  style={{
                    boxSizing: "border-box",
                    maxWidth: "100%",
                    marginTop: "0",
                    flex: "0 0 auto",
                    flexShrink: 0,
                    width: "66.6667%",
                    fontFamily: '"Open Sans", sans-serif',
                    paddingRight: "1rem",
                    paddingLeft: "1rem",
                    paddingTop: "0px",
                    paddingBottom: "0px",
                    textAlign: "left",
                  }}
                >
                  <h4
                    className="mb-1 fw-600 v"
                    style={{
                      boxSizing: "border-box",
                      marginTop: "0px",
                      lineHeight: 1.2,
                      fontSize: "1.5rem",
                      marginBottom: "0.25rem",
                      fontWeight: 600,
                    }}
                  >
                    <a
                      className="text-decoration-none"
                      href="https://v3.educaflix.co/home/instructor_page/1"
                      style={{
                        boxSizing: "border-box",
                        transition: "all 0.3s ease 0s",
                        color: "rgb(18, 34, 34)",
                        fontFamily: '"Open Sans", sans-serif',
                        textDecoration: "none",
                      }}
                    >
                      Sandro Monteiro
                    </a>
                  </h4>
                  <p
                    className="fw-500 text-14px w-100"
                    style={{
                      boxSizing: "border-box",
                      margin: "0px 0px 10.5px",
                      marginTop: "0px",
                      marginBottom: "10.5px",
                      width: "100%",
                      fontSize: "14px",
                      fontWeight: 500,
                    }}
                  />
                  <div
                    className="rating"
                    style={{
                      boxSizing: "border-box",
                      fontFamily: '"Open Sans", sans-serif',
                    }}
                  >
                    <div
                      className="d-inline-block mb-2"
                      style={{
                        boxSizing: "border-box",
                        fontFamily: '"Open Sans", sans-serif',
                        display: "inline-block",
                        marginBottom: "0.5rem",
                      }}
                    >
                      <span
                        className="text-dark fw-800 text-muted ms-1 text-13px"
                        style={{
                          boxSizing: "border-box",
                          fontSize: "13px",
                          fontFamily: '"Open Sans", sans-serif',
                          marginLeft: "0.25rem",
                          color: "rgb(108, 117, 125)",
                          fontWeight: 800,
                        }}
                      >
                        0 Avaliações
                      </span>
                      {"|"}
                      <span
                        className="text-dark fw-800 text-13px text-muted mx-1"
                        style={{
                          boxSizing: "border-box",
                          fontSize: "13px",
                          fontFamily: '"Open Sans", sans-serif',
                          marginRight: "0.25rem",
                          marginLeft: "0.25rem",
                          color: "rgb(108, 117, 125)",
                          fontWeight: 800,
                        }}
                      >
                        {"0 Alunos"}
                      </span>
                      {"|"}
                      <span
                        className="text-dark fw-800 text-14px text-muted"
                        style={{
                          boxSizing: "border-box",
                          fontFamily: '"Open Sans", sans-serif',
                          color: "rgb(108, 117, 125)",
                          fontSize: "14px",
                          fontWeight: 800,
                        }}
                      >
                        {"1 Cursos"}
                      </span>
                    </div>
                  </div>
                  <span
                    className="badge badge-sub-warning text-12px my-1 py-2"
                    style={{
                      boxSizing: "border-box",
                      whiteSpace: "nowrap",
                      fontWeight: 700,
                      lineHeight: 1,
                      textAlign: "center",
                      verticalAlign: "baseline",
                      fontSize: "12px",
                      padding: "5px 10px",
                      borderRadius: "5px",
                      display: "none",
                      fontFamily: '"Open Sans", sans-serif',
                      marginTop: "0.25rem",
                      marginBottom: "0.25rem",
                      paddingTop: "0.5rem",
                      paddingBottom: "0.5rem",
                      backgroundColor: "rgb(236, 235, 152)",
                      color: "rgb(60, 60, 45)",
                    }}
                  />
                </div>
              </div>
            </div>
            <div
              className="student-feedback-box mt-5 pb-3"
              style={{
                boxSizing: "border-box",
                fontFamily: '"Open Sans", sans-serif',
                marginTop: "3rem",
                paddingBottom: "1rem",
              }}
            >
              <div
                className="student-feedback-title"
                style={{
                  boxSizing: "border-box",
                  margin: "0px 0px 15px",
                  fontSize: "22px",
                  fontWeight: 600,
                  fontFamily: '"Open Sans", sans-serif',
                }}
              >
                {"Feedback do aluno"}
              </div>
              <div
                className="row"
                style={{
                  boxSizing: "border-box",
                  display: "flex",
                  flexWrap: "wrap",
                  marginTop: "calc(0 * -1)",
                  marginRight: "calc(1.5rem * -.5)",
                  marginLeft: "calc(1.5rem * -.5)",
                  fontFamily: '"Open Sans", sans-serif',
                }}
              >
                <div
                  className="col-md-12"
                  style={{
                    boxSizing: "border-box",
                    maxWidth: "100%",
                    paddingRight: "calc(1.5rem * .5)",
                    paddingLeft: "calc(1.5rem * .5)",
                    marginTop: "0",
                    flex: "0 0 auto",
                    flexShrink: 0,
                    width: "100%",
                    fontFamily: '"Open Sans", sans-serif',
                  }}
                >
                  <div
                    className="average-rating ms-auto me-auto float-md-start mb-sm-4"
                    style={{
                      boxSizing: "border-box",
                      border: "1px solid rgb(222, 223, 224)",
                      padding: "5px",
                      borderRadius: "10px",
                      height: "165px",
                      width: "170px",
                      lineHeight: "30px",
                      textAlign: "center",
                      fontFamily: '"Open Sans", sans-serif',
                      marginRight: "auto",
                      marginLeft: "auto",
                      marginBottom: "1.5rem",
                      cssFloat: "left",
                    }}
                  >
                    <div
                      className="num"
                      style={{
                        boxSizing: "border-box",
                        fontSize: "72px",
                        fontWeight: 500,
                        lineHeight: 1,
                        marginBottom: "10px",
                        fontFamily: '"Open Sans", sans-serif',
                      }}
                    >
                      {"0"}
                    </div>
                    <div
                      className="rating"
                      style={{
                        boxSizing: "border-box",
                        fontFamily: '"Open Sans", sans-serif',
                      }}
                    >
                      <i
                        className="fas fa-star"
                        style={{
                          boxSizing: "border-box",
                          fontVariant: "normal",
                          WebkitFontSmoothing: "antialiased",
                          display: "inline-block",
                          fontStyle: "normal",
                          textRendering: "auto",
                          lineHeight: 1,
                          fontFamily: '"Font Awesome 5 Free"',
                          fontWeight: 900,
                          fontSize: "20px",
                          marginBottom: "5px",
                          color: "rgb(171, 176, 187)",
                        }}
                      />
                      <i
                        className="fas fa-star"
                        style={{
                          boxSizing: "border-box",
                          fontVariant: "normal",
                          WebkitFontSmoothing: "antialiased",
                          display: "inline-block",
                          fontStyle: "normal",
                          textRendering: "auto",
                          lineHeight: 1,
                          fontFamily: '"Font Awesome 5 Free"',
                          fontWeight: 900,
                          fontSize: "20px",
                          marginBottom: "5px",
                          color: "rgb(171, 176, 187)",
                        }}
                      />
                      <i
                        className="fas fa-star"
                        style={{
                          boxSizing: "border-box",
                          fontVariant: "normal",
                          WebkitFontSmoothing: "antialiased",
                          display: "inline-block",
                          fontStyle: "normal",
                          textRendering: "auto",
                          lineHeight: 1,
                          fontFamily: '"Font Awesome 5 Free"',
                          fontWeight: 900,
                          fontSize: "20px",
                          marginBottom: "5px",
                          color: "rgb(171, 176, 187)",
                        }}
                      />
                      <i
                        className="fas fa-star"
                        style={{
                          boxSizing: "border-box",
                          fontVariant: "normal",
                          WebkitFontSmoothing: "antialiased",
                          display: "inline-block",
                          fontStyle: "normal",
                          textRendering: "auto",
                          lineHeight: 1,
                          fontFamily: '"Font Awesome 5 Free"',
                          fontWeight: 900,
                          fontSize: "20px",
                          marginBottom: "5px",
                          color: "rgb(171, 176, 187)",
                        }}
                      />
                      <i
                        className="fas fa-star"
                        style={{
                          boxSizing: "border-box",
                          fontVariant: "normal",
                          WebkitFontSmoothing: "antialiased",
                          display: "inline-block",
                          fontStyle: "normal",
                          textRendering: "auto",
                          lineHeight: 1,
                          fontFamily: '"Font Awesome 5 Free"',
                          fontWeight: 900,
                          fontSize: "20px",
                          marginBottom: "5px",
                          color: "rgb(171, 176, 187)",
                        }}
                      />
                    </div>
                    <div
                      className="title text-15px fw-700"
                      style={{
                        boxSizing: "border-box",
                        fontFamily: '"Open Sans", sans-serif',
                        fontSize: "15px",
                        fontWeight: 700,
                      }}
                    >
                      0 Avaliações
                    </div>
                  </div>
                  <div
                    className="individual-rating"
                    style={{
                      boxSizing: "border-box",
                      fontFamily: '"Open Sans", sans-serif',
                    }}
                  >
                    <ul
                      style={{
                        boxSizing: "border-box",
                        margin: "0px",
                        padding: "0px",
                        listStyle: "none",
                        marginTop: "0px",
                        marginBottom: "0px",
                        paddingLeft: "0px",
                        fontFamily: '"Open Sans", sans-serif',
                      }}
                    >
                      <li
                        style={{
                          boxSizing: "border-box",
                          display: "flex",
                          WebkitBoxAlign: "center",
                          alignItems: "center",
                          marginBottom: "10px",
                          fontFamily: '"Open Sans", sans-serif',
                        }}
                      >
                        <div
                          style={{
                            boxSizing: "border-box",
                            paddingLeft: "15px",
                            fontFamily: '"Open Sans", sans-serif',
                            minWidth: "120px",
                          }}
                        >
                          <span
                            className="rating"
                            style={{
                              boxSizing: "border-box",
                              fontFamily: '"Open Sans", sans-serif',
                            }}
                          >
                            <i
                              className="fas fa-star"
                              style={{
                                boxSizing: "border-box",
                                fontVariant: "normal",
                                WebkitFontSmoothing: "antialiased",
                                display: "inline-block",
                                fontStyle: "normal",
                                textRendering: "auto",
                                lineHeight: 1,
                                fontFamily: '"Font Awesome 5 Free"',
                                fontWeight: 900,
                                fontSize: "14px",
                                color: "rgb(222, 223, 224)",
                              }}
                            />
                            <i
                              className="fas fa-star"
                              style={{
                                boxSizing: "border-box",
                                fontVariant: "normal",
                                WebkitFontSmoothing: "antialiased",
                                display: "inline-block",
                                fontStyle: "normal",
                                textRendering: "auto",
                                lineHeight: 1,
                                fontFamily: '"Font Awesome 5 Free"',
                                fontWeight: 900,
                                fontSize: "14px",
                                color: "rgb(222, 223, 224)",
                              }}
                            />
                            <i
                              className="fas fa-star"
                              style={{
                                boxSizing: "border-box",
                                fontVariant: "normal",
                                WebkitFontSmoothing: "antialiased",
                                display: "inline-block",
                                fontStyle: "normal",
                                textRendering: "auto",
                                lineHeight: 1,
                                fontFamily: '"Font Awesome 5 Free"',
                                fontWeight: 900,
                                fontSize: "14px",
                                color: "rgb(222, 223, 224)",
                              }}
                            />
                            <i
                              className="fas fa-star"
                              style={{
                                boxSizing: "border-box",
                                fontVariant: "normal",
                                WebkitFontSmoothing: "antialiased",
                                display: "inline-block",
                                fontStyle: "normal",
                                textRendering: "auto",
                                lineHeight: 1,
                                fontFamily: '"Font Awesome 5 Free"',
                                fontWeight: 900,
                                fontSize: "14px",
                                color: "rgb(222, 223, 224)",
                              }}
                            />
                            <i
                              className="fas fa-star filled"
                              style={{
                                boxSizing: "border-box",
                                fontVariant: "normal",
                                WebkitFontSmoothing: "antialiased",
                                display: "inline-block",
                                fontStyle: "normal",
                                textRendering: "auto",
                                lineHeight: 1,
                                fontFamily: '"Font Awesome 5 Free"',
                                fontWeight: 900,
                                fontSize: "14px",
                                color: "rgb(244, 193, 80)",
                              }}
                            />
                          </span>
                        </div>
                        <div
                          className="progress ms-2 mt-1"
                          style={{
                            boxSizing: "border-box",
                            overflow: "hidden",
                            display: "flex",
                            fontSize: "0.75rem",
                            borderRadius: "5px",
                            width: "100%",
                            height: "10px",
                            backgroundColor: "rgb(236, 235, 152)",
                            fontFamily: '"Open Sans", sans-serif',
                            marginTop: "0.25rem",
                            marginLeft: "0.5rem",
                          }}
                        >
                          <div
                            className="progress-bar"
                            style={{
                              boxSizing: "border-box",
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              color: "rgb(255, 255, 255)",
                              textAlign: "center",
                              transition: "none 0s ease 0s",
                              borderRadius: "5px",
                              backgroundColor: "rgb(236, 82, 82)",
                              fontSize: "11px",
                              width: "0%",
                              fontFamily: '"Open Sans", sans-serif',
                            }}
                          />
                        </div>
                        <span
                          className="d-inline-block ps-2 text-15px fw-500"
                          style={{
                            boxSizing: "border-box",
                            fontFamily: '"Open Sans", sans-serif',
                            display: "inline-block",
                            paddingLeft: "0.5rem",
                            fontSize: "15px",
                            fontWeight: 500,
                          }}
                        >
                          {"(0)"}
                        </span>
                      </li>
                      <li
                        style={{
                          boxSizing: "border-box",
                          display: "flex",
                          WebkitBoxAlign: "center",
                          alignItems: "center",
                          marginBottom: "10px",
                          fontFamily: '"Open Sans", sans-serif',
                        }}
                      >
                        <div
                          style={{
                            boxSizing: "border-box",
                            paddingLeft: "15px",
                            fontFamily: '"Open Sans", sans-serif',
                            minWidth: "120px",
                          }}
                        >
                          <span
                            className="rating"
                            style={{
                              boxSizing: "border-box",
                              fontFamily: '"Open Sans", sans-serif',
                            }}
                          >
                            <i
                              className="fas fa-star"
                              style={{
                                boxSizing: "border-box",
                                fontVariant: "normal",
                                WebkitFontSmoothing: "antialiased",
                                display: "inline-block",
                                fontStyle: "normal",
                                textRendering: "auto",
                                lineHeight: 1,
                                fontFamily: '"Font Awesome 5 Free"',
                                fontWeight: 900,
                                fontSize: "14px",
                                color: "rgb(222, 223, 224)",
                              }}
                            />
                            <i
                              className="fas fa-star"
                              style={{
                                boxSizing: "border-box",
                                fontVariant: "normal",
                                WebkitFontSmoothing: "antialiased",
                                display: "inline-block",
                                fontStyle: "normal",
                                textRendering: "auto",
                                lineHeight: 1,
                                fontFamily: '"Font Awesome 5 Free"',
                                fontWeight: 900,
                                fontSize: "14px",
                                color: "rgb(222, 223, 224)",
                              }}
                            />
                            <i
                              className="fas fa-star"
                              style={{
                                boxSizing: "border-box",
                                fontVariant: "normal",
                                WebkitFontSmoothing: "antialiased",
                                display: "inline-block",
                                fontStyle: "normal",
                                textRendering: "auto",
                                lineHeight: 1,
                                fontFamily: '"Font Awesome 5 Free"',
                                fontWeight: 900,
                                fontSize: "14px",
                                color: "rgb(222, 223, 224)",
                              }}
                            />
                            <i
                              className="fas fa-star filled"
                              style={{
                                boxSizing: "border-box",
                                fontVariant: "normal",
                                WebkitFontSmoothing: "antialiased",
                                display: "inline-block",
                                fontStyle: "normal",
                                textRendering: "auto",
                                lineHeight: 1,
                                fontFamily: '"Font Awesome 5 Free"',
                                fontWeight: 900,
                                fontSize: "14px",
                                color: "rgb(244, 193, 80)",
                              }}
                            />
                            <i
                              className="fas fa-star filled"
                              style={{
                                boxSizing: "border-box",
                                fontVariant: "normal",
                                WebkitFontSmoothing: "antialiased",
                                display: "inline-block",
                                fontStyle: "normal",
                                textRendering: "auto",
                                lineHeight: 1,
                                fontFamily: '"Font Awesome 5 Free"',
                                fontWeight: 900,
                                fontSize: "14px",
                                color: "rgb(244, 193, 80)",
                              }}
                            />
                          </span>
                        </div>
                        <div
                          className="progress ms-2 mt-1"
                          style={{
                            boxSizing: "border-box",
                            overflow: "hidden",
                            display: "flex",
                            fontSize: "0.75rem",
                            borderRadius: "5px",
                            width: "100%",
                            height: "10px",
                            backgroundColor: "rgb(236, 235, 152)",
                            fontFamily: '"Open Sans", sans-serif',
                            marginTop: "0.25rem",
                            marginLeft: "0.5rem",
                          }}
                        >
                          <div
                            className="progress-bar"
                            style={{
                              boxSizing: "border-box",
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              color: "rgb(255, 255, 255)",
                              textAlign: "center",
                              transition: "none 0s ease 0s",
                              borderRadius: "5px",
                              backgroundColor: "rgb(236, 82, 82)",
                              fontSize: "11px",
                              width: "0%",
                              fontFamily: '"Open Sans", sans-serif',
                            }}
                          />
                        </div>
                        <span
                          className="d-inline-block ps-2 text-15px fw-500"
                          style={{
                            boxSizing: "border-box",
                            fontFamily: '"Open Sans", sans-serif',
                            display: "inline-block",
                            paddingLeft: "0.5rem",
                            fontSize: "15px",
                            fontWeight: 500,
                          }}
                        >
                          {"(0)"}
                        </span>
                      </li>
                      <li
                        style={{
                          boxSizing: "border-box",
                          display: "flex",
                          WebkitBoxAlign: "center",
                          alignItems: "center",
                          marginBottom: "10px",
                          fontFamily: '"Open Sans", sans-serif',
                        }}
                      >
                        <div
                          style={{
                            boxSizing: "border-box",
                            paddingLeft: "15px",
                            fontFamily: '"Open Sans", sans-serif',
                            minWidth: "120px",
                          }}
                        >
                          <span
                            className="rating"
                            style={{
                              boxSizing: "border-box",
                              fontFamily: '"Open Sans", sans-serif',
                            }}
                          >
                            <i
                              className="fas fa-star"
                              style={{
                                boxSizing: "border-box",
                                fontVariant: "normal",
                                WebkitFontSmoothing: "antialiased",
                                display: "inline-block",
                                fontStyle: "normal",
                                textRendering: "auto",
                                lineHeight: 1,
                                fontFamily: '"Font Awesome 5 Free"',
                                fontWeight: 900,
                                fontSize: "14px",
                                color: "rgb(222, 223, 224)",
                              }}
                            />
                            <i
                              className="fas fa-star"
                              style={{
                                boxSizing: "border-box",
                                fontVariant: "normal",
                                WebkitFontSmoothing: "antialiased",
                                display: "inline-block",
                                fontStyle: "normal",
                                textRendering: "auto",
                                lineHeight: 1,
                                fontFamily: '"Font Awesome 5 Free"',
                                fontWeight: 900,
                                fontSize: "14px",
                                color: "rgb(222, 223, 224)",
                              }}
                            />
                            <i
                              className="fas fa-star filled"
                              style={{
                                boxSizing: "border-box",
                                fontVariant: "normal",
                                WebkitFontSmoothing: "antialiased",
                                display: "inline-block",
                                fontStyle: "normal",
                                textRendering: "auto",
                                lineHeight: 1,
                                fontFamily: '"Font Awesome 5 Free"',
                                fontWeight: 900,
                                fontSize: "14px",
                                color: "rgb(244, 193, 80)",
                              }}
                            />
                            <i
                              className="fas fa-star filled"
                              style={{
                                boxSizing: "border-box",
                                fontVariant: "normal",
                                WebkitFontSmoothing: "antialiased",
                                display: "inline-block",
                                fontStyle: "normal",
                                textRendering: "auto",
                                lineHeight: 1,
                                fontFamily: '"Font Awesome 5 Free"',
                                fontWeight: 900,
                                fontSize: "14px",
                                color: "rgb(244, 193, 80)",
                              }}
                            />
                            <i
                              className="fas fa-star filled"
                              style={{
                                boxSizing: "border-box",
                                fontVariant: "normal",
                                WebkitFontSmoothing: "antialiased",
                                display: "inline-block",
                                fontStyle: "normal",
                                textRendering: "auto",
                                lineHeight: 1,
                                fontFamily: '"Font Awesome 5 Free"',
                                fontWeight: 900,
                                fontSize: "14px",
                                color: "rgb(244, 193, 80)",
                              }}
                            />
                          </span>
                        </div>
                        <div
                          className="progress ms-2 mt-1"
                          style={{
                            boxSizing: "border-box",
                            overflow: "hidden",
                            display: "flex",
                            fontSize: "0.75rem",
                            borderRadius: "5px",
                            width: "100%",
                            height: "10px",
                            backgroundColor: "rgb(236, 235, 152)",
                            fontFamily: '"Open Sans", sans-serif',
                            marginTop: "0.25rem",
                            marginLeft: "0.5rem",
                          }}
                        >
                          <div
                            className="progress-bar"
                            style={{
                              boxSizing: "border-box",
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              color: "rgb(255, 255, 255)",
                              textAlign: "center",
                              transition: "none 0s ease 0s",
                              borderRadius: "5px",
                              backgroundColor: "rgb(236, 82, 82)",
                              fontSize: "11px",
                              width: "0%",
                              fontFamily: '"Open Sans", sans-serif',
                            }}
                          />
                        </div>
                        <span
                          className="d-inline-block ps-2 text-15px fw-500"
                          style={{
                            boxSizing: "border-box",
                            fontFamily: '"Open Sans", sans-serif',
                            display: "inline-block",
                            paddingLeft: "0.5rem",
                            fontSize: "15px",
                            fontWeight: 500,
                          }}
                        >
                          {"(0)"}
                        </span>
                      </li>
                      <li
                        style={{
                          boxSizing: "border-box",
                          display: "flex",
                          WebkitBoxAlign: "center",
                          alignItems: "center",
                          marginBottom: "10px",
                          fontFamily: '"Open Sans", sans-serif',
                        }}
                      >
                        <div
                          style={{
                            boxSizing: "border-box",
                            paddingLeft: "15px",
                            fontFamily: '"Open Sans", sans-serif',
                            minWidth: "120px",
                          }}
                        >
                          <span
                            className="rating"
                            style={{
                              boxSizing: "border-box",
                              fontFamily: '"Open Sans", sans-serif',
                            }}
                          >
                            <i
                              className="fas fa-star"
                              style={{
                                boxSizing: "border-box",
                                fontVariant: "normal",
                                WebkitFontSmoothing: "antialiased",
                                display: "inline-block",
                                fontStyle: "normal",
                                textRendering: "auto",
                                lineHeight: 1,
                                fontFamily: '"Font Awesome 5 Free"',
                                fontWeight: 900,
                                fontSize: "14px",
                                color: "rgb(222, 223, 224)",
                              }}
                            />
                            <i
                              className="fas fa-star filled"
                              style={{
                                boxSizing: "border-box",
                                fontVariant: "normal",
                                WebkitFontSmoothing: "antialiased",
                                display: "inline-block",
                                fontStyle: "normal",
                                textRendering: "auto",
                                lineHeight: 1,
                                fontFamily: '"Font Awesome 5 Free"',
                                fontWeight: 900,
                                fontSize: "14px",
                                color: "rgb(244, 193, 80)",
                              }}
                            />
                            <i
                              className="fas fa-star filled"
                              style={{
                                boxSizing: "border-box",
                                fontVariant: "normal",
                                WebkitFontSmoothing: "antialiased",
                                display: "inline-block",
                                fontStyle: "normal",
                                textRendering: "auto",
                                lineHeight: 1,
                                fontFamily: '"Font Awesome 5 Free"',
                                fontWeight: 900,
                                fontSize: "14px",
                                color: "rgb(244, 193, 80)",
                              }}
                            />
                            <i
                              className="fas fa-star filled"
                              style={{
                                boxSizing: "border-box",
                                fontVariant: "normal",
                                WebkitFontSmoothing: "antialiased",
                                display: "inline-block",
                                fontStyle: "normal",
                                textRendering: "auto",
                                lineHeight: 1,
                                fontFamily: '"Font Awesome 5 Free"',
                                fontWeight: 900,
                                fontSize: "14px",
                                color: "rgb(244, 193, 80)",
                              }}
                            />
                            <i
                              className="fas fa-star filled"
                              style={{
                                boxSizing: "border-box",
                                fontVariant: "normal",
                                WebkitFontSmoothing: "antialiased",
                                display: "inline-block",
                                fontStyle: "normal",
                                textRendering: "auto",
                                lineHeight: 1,
                                fontFamily: '"Font Awesome 5 Free"',
                                fontWeight: 900,
                                fontSize: "14px",
                                color: "rgb(244, 193, 80)",
                              }}
                            />
                          </span>
                        </div>
                        <div
                          className="progress ms-2 mt-1"
                          style={{
                            boxSizing: "border-box",
                            overflow: "hidden",
                            display: "flex",
                            fontSize: "0.75rem",
                            borderRadius: "5px",
                            width: "100%",
                            height: "10px",
                            backgroundColor: "rgb(236, 235, 152)",
                            fontFamily: '"Open Sans", sans-serif',
                            marginTop: "0.25rem",
                            marginLeft: "0.5rem",
                          }}
                        >
                          <div
                            className="progress-bar"
                            style={{
                              boxSizing: "border-box",
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              color: "rgb(255, 255, 255)",
                              textAlign: "center",
                              transition: "none 0s ease 0s",
                              borderRadius: "5px",
                              backgroundColor: "rgb(236, 82, 82)",
                              fontSize: "11px",
                              width: "0%",
                              fontFamily: '"Open Sans", sans-serif',
                            }}
                          />
                        </div>
                        <span
                          className="d-inline-block ps-2 text-15px fw-500"
                          style={{
                            boxSizing: "border-box",
                            fontFamily: '"Open Sans", sans-serif',
                            display: "inline-block",
                            paddingLeft: "0.5rem",
                            fontSize: "15px",
                            fontWeight: 500,
                          }}
                        >
                          {"(0)"}
                        </span>
                      </li>
                      <li
                        style={{
                          boxSizing: "border-box",
                          display: "flex",
                          WebkitBoxAlign: "center",
                          alignItems: "center",
                          marginBottom: "10px",
                          fontFamily: '"Open Sans", sans-serif',
                        }}
                      >
                        <div
                          style={{
                            boxSizing: "border-box",
                            paddingLeft: "15px",
                            fontFamily: '"Open Sans", sans-serif',
                            minWidth: "120px",
                          }}
                        >
                          <span
                            className="rating"
                            style={{
                              boxSizing: "border-box",
                              fontFamily: '"Open Sans", sans-serif',
                            }}
                          >
                            <i
                              className="fas fa-star filled"
                              style={{
                                boxSizing: "border-box",
                                fontVariant: "normal",
                                WebkitFontSmoothing: "antialiased",
                                display: "inline-block",
                                fontStyle: "normal",
                                textRendering: "auto",
                                lineHeight: 1,
                                fontFamily: '"Font Awesome 5 Free"',
                                fontWeight: 900,
                                fontSize: "14px",
                                color: "rgb(244, 193, 80)",
                              }}
                            />
                            <i
                              className="fas fa-star filled"
                              style={{
                                boxSizing: "border-box",
                                fontVariant: "normal",
                                WebkitFontSmoothing: "antialiased",
                                display: "inline-block",
                                fontStyle: "normal",
                                textRendering: "auto",
                                lineHeight: 1,
                                fontFamily: '"Font Awesome 5 Free"',
                                fontWeight: 900,
                                fontSize: "14px",
                                color: "rgb(244, 193, 80)",
                              }}
                            />
                            <i
                              className="fas fa-star filled"
                              style={{
                                boxSizing: "border-box",
                                fontVariant: "normal",
                                WebkitFontSmoothing: "antialiased",
                                display: "inline-block",
                                fontStyle: "normal",
                                textRendering: "auto",
                                lineHeight: 1,
                                fontFamily: '"Font Awesome 5 Free"',
                                fontWeight: 900,
                                fontSize: "14px",
                                color: "rgb(244, 193, 80)",
                              }}
                            />
                            <i
                              className="fas fa-star filled"
                              style={{
                                boxSizing: "border-box",
                                fontVariant: "normal",
                                WebkitFontSmoothing: "antialiased",
                                display: "inline-block",
                                fontStyle: "normal",
                                textRendering: "auto",
                                lineHeight: 1,
                                fontFamily: '"Font Awesome 5 Free"',
                                fontWeight: 900,
                                fontSize: "14px",
                                color: "rgb(244, 193, 80)",
                              }}
                            />
                            <i
                              className="fas fa-star filled"
                              style={{
                                boxSizing: "border-box",
                                fontVariant: "normal",
                                WebkitFontSmoothing: "antialiased",
                                display: "inline-block",
                                fontStyle: "normal",
                                textRendering: "auto",
                                lineHeight: 1,
                                fontFamily: '"Font Awesome 5 Free"',
                                fontWeight: 900,
                                fontSize: "14px",
                                color: "rgb(244, 193, 80)",
                              }}
                            />
                          </span>
                        </div>
                        <div
                          className="progress ms-2 mt-1"
                          style={{
                            boxSizing: "border-box",
                            overflow: "hidden",
                            display: "flex",
                            fontSize: "0.75rem",
                            borderRadius: "5px",
                            width: "100%",
                            height: "10px",
                            backgroundColor: "rgb(236, 235, 152)",
                            fontFamily: '"Open Sans", sans-serif',
                            marginTop: "0.25rem",
                            marginLeft: "0.5rem",
                          }}
                        >
                          <div
                            className="progress-bar"
                            style={{
                              boxSizing: "border-box",
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              color: "rgb(255, 255, 255)",
                              textAlign: "center",
                              transition: "none 0s ease 0s",
                              borderRadius: "5px",
                              backgroundColor: "rgb(236, 82, 82)",
                              fontSize: "11px",
                              width: "0%",
                              fontFamily: '"Open Sans", sans-serif',
                            }}
                          />
                        </div>
                        <span
                          className="d-inline-block ps-2 text-15px fw-500"
                          style={{
                            boxSizing: "border-box",
                            fontFamily: '"Open Sans", sans-serif',
                            display: "inline-block",
                            paddingLeft: "0.5rem",
                            fontSize: "15px",
                            fontWeight: 500,
                          }}
                        >
                          {"(0)"}
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div
                className="reviews mt-5"
                style={{
                  boxSizing: "border-box",
                  fontFamily: '"Open Sans", sans-serif',
                  marginTop: "3rem",
                }}
              >
                <h3
                  style={{
                    boxSizing: "border-box",
                    marginTop: "0px",
                    marginBottom: "0.5rem",
                    fontWeight: 500,
                    lineHeight: 1.2,
                    fontSize: "1.75rem",
                  }}
                >
                  Avaliações
                </h3>
                <ul
                  style={{
                    boxSizing: "border-box",
                    listStyle: "none",
                    padding: "0px",
                    margin: "0px",
                    paddingLeft: "0px",
                    marginTop: "0px",
                    marginBottom: "0px",
                    fontFamily: '"Open Sans", sans-serif',
                  }}
                />
              </div>
            </div>
          </div>
          <div
            className="col-lg-4 order-first order-lg-last"
            style={{
              boxSizing: "border-box",
              maxWidth: "100%",
              paddingRight: "calc(1.5rem * .5)",
              paddingLeft: "calc(1.5rem * .5)",
              marginTop: "0",
              flex: "0 0 auto",
              flexShrink: 0,
              width: "33.3333%",
              fontFamily: '"Open Sans", sans-serif',
              order: 6,
            }}
          >
            <div
              className="course-sidebar box-shadow-5 natural"
              style={{
                boxSizing: "border-box",
                borderRadius: "8px",
                padding: "10px",
                backgroundColor: "rgb(255, 255, 255)",
                color: "rgb(80, 87, 99)",
                position: "relative",
                marginTop: "-250px",
                zIndex: 10,
                top: "auto",
                fontFamily: '"Open Sans", sans-serif',
                boxShadow: "rgba(0, 0, 0, 0.11) 0px 1px 5px",
              }}
            >
              <div
                className="preview-video-box"
                style={{
                  boxSizing: "border-box",
                  fontFamily: '"Open Sans", sans-serif',
                }}
              >
                <a
                  style={{
                    boxSizing: "border-box",
                    transition: "all 0.3s ease 0s",
                    overflow: "hidden",
                    borderRadius: "10px 10px 0px 0px",
                    display: "block",
                    position: "relative",
                    textDecoration: "none",
                    color: "inherit",
                    fontFamily: '"Open Sans", sans-serif',
                  }}
                >
                  <img
                    className="w-100"
                    src="https://v3.educaflix.co/assets/frontend/default/img/course_thumbnail_placeholder.jpg"
                    style={{
                      boxSizing: "border-box",
                      verticalAlign: "middle",
                      width: "100%",
                    }}
                  />
                  <span
                    className="preview-text"
                    style={{
                      boxSizing: "border-box",
                      position: "absolute",
                      width: "100%",
                      bottom: "10px",
                      left: "0px",
                      textAlign: "center",
                      height: "auto",
                      fontSize: "15px",
                      fontWeight: 700,
                      fontFamily: '"Open Sans", sans-serif',
                    }}
                  >
                    Pré-visualizar este curso
                  </span>
                  <span
                    className="play-btn"
                    style={{
                      boxSizing: "border-box",
                      background:
                        'url("https://v3.educaflix.co/assets/frontend/default/img/icons/icon-play.svg") 50% center / auto 50% no-repeat',
                      transition: "transform 0.15s ease-in-out 0s",
                      left: "0px",
                      top: "0px",
                      width: "100%",
                      height: "100%",
                      position: "absolute",
                      visibility: "visible",
                      fontFamily: '"Open Sans", sans-serif',
                    }}
                  />
                </a>
              </div>
              <div
                className="course-sidebar-text-box"
                style={{
                  boxSizing: "border-box",
                  padding: "15px 30px",
                  fontFamily: '"Open Sans", sans-serif',
                }}
              >
                <div
                  className="price text-center"
                  style={{
                    boxSizing: "border-box",
                    fontFamily: '"Open Sans", sans-serif',
                    textAlign: "center",
                  }}
                >
                  <span
                    className="current-price"
                    style={{
                      boxSizing: "border-box",
                      verticalAlign: "middle",
                      color: "rgb(80, 87, 99)",
                      fontSize: "36px",
                      fontWeight: 700,
                      lineHeight: "40px",
                      marginRight: "10px",
                      fontFamily: '"Open Sans", sans-serif',
                    }}
                  >
                    <span
                      className="current-price"
                      style={{
                        boxSizing: "border-box",
                        verticalAlign: "middle",
                        color: "rgb(80, 87, 99)",
                        fontSize: "36px",
                        fontWeight: 700,
                        lineHeight: "40px",
                        marginRight: "10px",
                        fontFamily: '"Open Sans", sans-serif',
                      }}
                    >
                      R$59.9
                    </span>
                  </span>
                  <input
                    id="total_price_of_checking_out"
                    type="hidden"
                    defaultValue="R$59.9"
                    style={{
                      boxSizing: "border-box",
                      margin: "0px",
                      fontFamily: "inherit",
                      fontSize: "inherit",
                      lineHeight: "inherit",
                    }}
                  />
                </div>
                <div
                  className="buy-btns"
                  style={{
                    boxSizing: "border-box",
                    fontFamily: '"Open Sans", sans-serif',
                  }}
                >
                  <button
                    id="2"
                    className="btn btn-add-wishlist"
                    type="button"
                    style={{
                      boxSizing: "border-box",
                      textTransform: "none",
                      appearance: "button",
                      textDecoration: "none",
                      border: "1px solid transparent",
                      lineHeight: 1.5,
                      textAlign: "center",
                      verticalAlign: "middle",
                      userSelect: "none",
                      transition: "none 0s ease 0s",
                      cursor: "pointer",
                      margin: "13px 0px 10px",
                      padding: "12px 10px",
                      display: "block",
                      width: "100%",
                      fontSize: "14.5px",
                      fontWeight: 600,
                      borderColor: "rgb(236, 82, 82)",
                      borderRadius: "10px",
                      color: "rgb(236, 82, 82)",
                      backgroundColor: "rgb(255, 255, 255)",
                      fontFamily: '"Open Sans", sans-serif',
                    }}
                  >
                    {"Adicionar à lista de desejos"}
                  </button>
                </div>
                <div
                  className="buy-btns"
                  style={{
                    boxSizing: "border-box",
                    fontFamily: '"Open Sans", sans-serif',
                  }}
                >
                  <button
                    id="2"
                    className="btn btn-buy-now"
                    type="button"
                    style={{
                      boxSizing: "border-box",
                      textTransform: "none",
                      appearance: "button",
                      textDecoration: "none",
                      border: "1px solid transparent",
                      lineHeight: 1.5,
                      textAlign: "center",
                      verticalAlign: "middle",
                      userSelect: "none",
                      transition: "none 0s ease 0s",
                      cursor: "pointer",
                      margin: "13px 0px 10px",
                      padding: "12px 10px",
                      display: "block",
                      width: "100%",
                      fontSize: "14.5px",
                      fontWeight: 600,
                      borderColor: "rgb(126, 178, 0)",
                      borderRadius: "10px",
                      color: "rgb(126, 178, 0)",
                      backgroundColor: "rgb(255, 255, 255)",
                      fontFamily: '"Open Sans", sans-serif',
                    }}
                  >
                    Adicionar ao carrinho
                  </button>
                  <button
                    id="course_2"
                    className="btn btn-buy"
                    type="button"
                    style={{
                      boxSizing: "border-box",
                      textTransform: "none",
                      appearance: "button",
                      textDecoration: "none",
                      border: "1px solid transparent",
                      lineHeight: 1.5,
                      textAlign: "center",
                      verticalAlign: "middle",
                      userSelect: "none",
                      transition: "none 0s ease 0s",
                      cursor: "pointer",
                      margin: "13px 0px 10px",
                      padding: "12px 10px",
                      display: "block",
                      width: "100%",
                      fontSize: "14.5px",
                      fontWeight: 600,
                      borderColor: "rgb(202, 149, 0)",
                      borderRadius: "10px",
                      color: "rgb(202, 149, 0)",
                      backgroundColor: "rgb(255, 255, 255)",
                      fontFamily: '"Open Sans", sans-serif',
                    }}
                  >
                    Comprar agora
                  </button>
                </div>
                <div
                  className="includes"
                  style={{
                    boxSizing: "border-box",
                    marginBottom: "15px",
                    fontFamily: '"Open Sans", sans-serif',
                  }}
                >
                  <div
                    className="title"
                    style={{
                      boxSizing: "border-box",
                      fontFamily: '"Open Sans", sans-serif',
                    }}
                  >
                    <b
                      style={{ boxSizing: "border-box", fontWeight: "bolder" }}
                    >
                      Inclui:
                    </b>
                  </div>
                  <ul
                    style={{
                      boxSizing: "border-box",
                      margin: "0px",
                      padding: "0px",
                      listStyle: "none",
                      marginTop: "0px",
                      marginBottom: "0px",
                      paddingLeft: "0px",
                      fontFamily: '"Open Sans", sans-serif',
                    }}
                  >
                    <li
                      style={{
                        boxSizing: "border-box",
                        padding: "3px",
                        fontSize: "13px",
                        fontFamily: '"Open Sans", sans-serif',
                      }}
                    >
                      <i
                        className="far fa-file-video"
                        style={{
                          boxSizing: "border-box",
                          fontVariant: "normal",
                          WebkitFontSmoothing: "antialiased",
                          display: "inline-block",
                          fontStyle: "normal",
                          textRendering: "auto",
                          lineHeight: 1,
                          fontWeight: 400,
                          fontFamily: '"Font Awesome 5 Free"',
                          width: "19px",
                          fontSize: "12px",
                        }}
                      />
                      {"00:46:09 Horas Vídeos sob demanda"}
                    </li>
                    <li
                      style={{
                        boxSizing: "border-box",
                        padding: "3px",
                        fontSize: "13px",
                        fontFamily: '"Open Sans", sans-serif',
                      }}
                    >
                      <i
                        className="far fa-file"
                        style={{
                          boxSizing: "border-box",
                          fontVariant: "normal",
                          WebkitFontSmoothing: "antialiased",
                          display: "inline-block",
                          fontStyle: "normal",
                          textRendering: "auto",
                          lineHeight: 1,
                          fontWeight: 400,
                          fontFamily: '"Font Awesome 5 Free"',
                          width: "19px",
                          fontSize: "12px",
                        }}
                      />
                      3 aulas
                    </li>
                    <li
                      style={{
                        boxSizing: "border-box",
                        padding: "3px",
                        fontSize: "13px",
                        fontFamily: '"Open Sans", sans-serif',
                      }}
                    >
                      <i
                        className="fas fa-mobile-alt"
                        style={{
                          boxSizing: "border-box",
                          fontVariant: "normal",
                          WebkitFontSmoothing: "antialiased",
                          display: "inline-block",
                          fontStyle: "normal",
                          textRendering: "auto",
                          lineHeight: 1,
                          fontFamily: '"Font Awesome 5 Free"',
                          fontWeight: 900,
                          width: "19px",
                          fontSize: "12px",
                        }}
                      />
                      Acesso em dispositivos móveis e TV
                    </li>
                    <li
                      style={{
                        boxSizing: "border-box",
                        padding: "3px",
                        fontSize: "13px",
                        fontFamily: '"Open Sans", sans-serif',
                      }}
                    >
                      <i
                        className="far fa-compass"
                        style={{
                          boxSizing: "border-box",
                          fontVariant: "normal",
                          WebkitFontSmoothing: "antialiased",
                          display: "inline-block",
                          fontStyle: "normal",
                          textRendering: "auto",
                          lineHeight: 1,
                          fontWeight: 400,
                          fontFamily: '"Font Awesome 5 Free"',
                          width: "19px",
                          fontSize: "12px",
                        }}
                      />
                      Acesso vitalício completo
                    </li>
                    <li
                      className="text-center pt-3"
                      style={{
                        boxSizing: "border-box",
                        padding: "3px",
                        fontSize: "13px",
                        fontFamily: '"Open Sans", sans-serif',
                        paddingTop: "1rem",
                        textAlign: "center",
                      }}
                    >
                      <a
                        className="badge-sub-warning text-decoration-none fw-600 hover-shadow-1 d-inline-block"
                        href="https://v3.educaflix.co/home/compare?course-1=cfp-curso-de-forma%C3%A7%C3%A3o-de-pra%C3%A7as&&course-id-1=2"
                        style={{
                          boxSizing: "border-box",
                          transition: "all 0.3s ease 0s",
                          padding: "5px 10px",
                          borderRadius: "5px",
                          fontFamily: '"Open Sans", sans-serif',
                          display: "inline-block",
                          textDecoration: "none",
                          fontWeight: 600,
                          backgroundColor: "rgb(236, 235, 152)",
                          color: "rgb(60, 60, 45)",
                        }}
                      >
                        <i
                          className="fas fa-balance-scale"
                          style={{
                            boxSizing: "border-box",
                            fontVariant: "normal",
                            WebkitFontSmoothing: "antialiased",
                            display: "inline-block",
                            fontStyle: "normal",
                            textRendering: "auto",
                            lineHeight: 1,
                            fontFamily: '"Font Awesome 5 Free"',
                            fontWeight: 900,
                            width: "19px",
                            fontSize: "12px",
                          }}
                        />{" "}
                        Comparar este curso com outros
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <style
        dangerouslySetInnerHTML={{
          __html: `
html {
  box-sizing: border-box;
  color: rgb(34, 34, 34);
  font-size: 1em;
  line-height: 1.4;
}

body {
  box-sizing: border-box;
  margin: 0px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: rgb(33, 37, 41);
  text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
  background: rgb(255, 255, 255);
  background-color: rgb(255, 255, 255);
  font-family: "Open Sans", sans-serif;
}
`,
        }}
      />
    </>
  );
}
