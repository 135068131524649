import React from "react";
import CollectionsCard from "./../CollectionsCard";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Collections.css";
const Collections = () => {
  const cards = [
    {
      brand: "formapara",
      image:
        "https://firebasestorage.googleapis.com/v0/b/chromatic-craft-400013.appspot.com/o/images%2Fcards%2Fsectet%2F01.png?alt=media&token=dd932947-07fa-4a30-8659-3b368e175299",
      video:
        "https://firebasestorage.googleapis.com/v0/b/chromatic-craft-400013.appspot.com/o/images%2Fcards%2Fsectet%2Fvideos-background%2F01.mp4?alt=media&token=a983b1e3-673c-48d9-b05f-7effa34dc894",
    },
    {
      brand: "cop30",
      image:
        "https://firebasestorage.googleapis.com/v0/b/chromatic-craft-400013.appspot.com/o/images%2Fcards%2Fsectet%2F02.png?alt=media&token=8ddf0cf2-8830-499c-93fc-4b1cd876d8a3",
      video:
        "https://firebasestorage.googleapis.com/v0/b/chromatic-craft-400013.appspot.com/o/images%2Fcards%2Fsectet%2Fvideos-background%2F02.mp4?alt=media&token=55b9d9f4-4088-48a2-9a33-45d85cac8238",
    },
    {
      brand: "enempara",
      image:
        "https://firebasestorage.googleapis.com/v0/b/chromatic-craft-400013.appspot.com/o/images%2Fcards%2Fsectet%2F03.png?alt=media&token=97644437-9dd6-4544-b7a3-bee4369f9d34",
      video:
        "https://firebasestorage.googleapis.com/v0/b/chromatic-craft-400013.appspot.com/o/images%2Fcards%2Fsectet%2Fvideos-background%2F03.mp4?alt=media&token=17dd2111-dcaf-4775-83b2-1781f8cf52f2",
    },
    {
      brand: "terpaz",
      image:
        "https://firebasestorage.googleapis.com/v0/b/chromatic-craft-400013.appspot.com/o/images%2Fcards%2Fsectet%2F04.png?alt=media&token=d95ce4d5-2fbc-4f41-83b8-73626cbf42de",
      video:
        "https://firebasestorage.googleapis.com/v0/b/chromatic-craft-400013.appspot.com/o/images%2Fcards%2Fsectet%2Fvideos-background%2F04.mp4?alt=media&token=b93ba1d2-9ced-4043-b927-f2cfc63d79c7",
    },
    {
      brand: "sectet",
      image:
        "https://firebasestorage.googleapis.com/v0/b/chromatic-craft-400013.appspot.com/o/images%2Fcards%2Fsectet%2F05.png?alt=media&token=7dc54004-e07c-4188-b15e-1c063a80b5a4",
      video:
        "https://firebasestorage.googleapis.com/v0/b/chromatic-craft-400013.appspot.com/o/images%2Fcards%2Fsectet%2Fvideos-background%2F05.mp4?alt=media&token=b8c5cf6b-8d9c-4b12-9afb-9f5dd9bfbdfe",
    },
  ];
  const config = {
    slidesToShow: 2,
    slidesToScroll: 1,
    mobileFirst: true,
    responsive: [
      {
        breakpoint: 768,
      },
      {
        breakpoint: 10000, // a unrealistically big number to cover up greatest screen resolution
        settings: "unslick",
      },
    ],
  };
  return (
    <section className="collections">
      <Slider {...config}>
        {cards.map((card) => (
          <Link key={card.brand} to={`/brand/${card.brand}`}>
            <CollectionsCard
              key={card.brand}
              brand={card.brand}
              video={card.video}
              image={card.image}
            />
          </Link>
        ))}
      </Slider>
    </section>
  );
};

export default Collections;
