import React, { useState } from "react";
import { useAuth } from "../../providers/auth";
import Avatar from "@material-ui/core/Avatar";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"; // Importe o FontAwesome
import { faUser, faSignOutAlt } from "@fortawesome/free-solid-svg-icons"; // Importe os ícones necessários
import "./UserSelector.css";

const UserSelector = () => {
  const { user, setUser } = useAuth();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handlePerfil = () => {
    setAnchorEl(null);
    history.push("/perfil");
  };

  const handleLogout = () => {
    setUser({
      user_id: "",
      first_name: "",
      last_name: "",
      email: "",
      role: "",
      validity: 1,
      token: "",
    });
    setAnchorEl(null);
    history.push("/login");
  };

  return (
    <div className="userSelector">
      <div className="userSelector__name">{user.first_name}</div>
      <Avatar
        src={user.image}
        onClick={handleClick}
        style={{ cursor: "pointer" }}
      />
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        style={{ marginTop: "50px" }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem className="item-menu" onClick={handlePerfil}>
          <FontAwesomeIcon
            color="#4d4b4b"
            icon={faUser}
            style={{ marginRight: "10px" }}
          />
          Perfil
        </MenuItem>
        {/* <MenuItem onClick={handleClose}>Configurações</MenuItem> */}
        <MenuItem className="item-menu" onClick={handleLogout}>
          <FontAwesomeIcon
            color="#4d4b4b"
            icon={faSignOutAlt}
            style={{ marginRight: "10px" }}
          />
          Sair
        </MenuItem>
      </Menu>
    </div>
  );
};

export default UserSelector;
